import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { Post } from './post';

import { MAIN } from '../../main';
import { ErrorCode } from '../../constant/errorcode';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { AuthService } from '../../user/auth.service';
import { ReleaseNote } from './releasenote';

@Injectable()
export class PostService {

    private postUrl = MAIN.URL.API_URL + 'post';
    private releaseNoteUrl = MAIN.URL.API_URL + 'post/releasenote';

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    // private handleError(error: any): Promise<any> {
    //     console.error('An error occured', error);
    //     return Promise.reject(error.message || error);
    // }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }

    // Post
    getPost(id: string): Observable<Post> {
        return this.http.get(
            this.postUrl + '/' + id,
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            }
        )
            .pipe(map((res: any) => res.post as Post))
            .pipe(
                catchError(this.handleError)
            );
    }

    getPosts(searchWord: string, startIndex: number, pageSize: number): Observable<any> {
        return this.http.get(
            this.postUrl,
            {
                params: new HttpParams()
                    //.set('code', this.authService.currentUser.code)
                    .set('from', 'web_admin')
                    .set('startIndex', startIndex.toString())
                    .set('pageSize', pageSize.toString())
                    .set('searchWord', searchWord)

            }
        )
            .pipe(map((response: any) => ((res: any) => {
                let currentDate: Date = new Date();
                return res.posts;
            })))
            .pipe(
                catchError(this.handleError)
            );
    }

    addPost(body: Object): Observable<any> {
        //body['storeCode'] = this.authService.currentStore.storeCode;

        return this.http.post(
            this.postUrl,
            body,
            // {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         //'Authorization': this.authService.currentUser.code,
            //         'QonUSFrom': 'qonus_web',
            //         'QonUSApi': 'v1.0',
            //     })
            // }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }

    updatePost(id: string, body: Object): Observable<any> {
        return this.http.put(
            this.postUrl + "/" + id,
            body,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.authService.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }

    deletePost(id: string): Observable<any> {
        return this.http.delete(
            this.postUrl + "/" + id,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.authService.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }


    // ReleaseNote
    getReleaseNote(releaseType: number, version: string): Observable<ReleaseNote> {
        return this.http.get(
            this.releaseNoteUrl + '/show',
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                params: new HttpParams()
                    //.set('code', this.authService.currentUser.code)
                    .set('from', 'web_admin')
                    .set('releaseType', releaseType.toString())
                    .set('version', version)
            }
        )
            .pipe(map((res: any) => res.releaseNote as ReleaseNote))
            .pipe(
                catchError(this.handleError)
            );
    }

    getReleaseNotes(releaseType: number, startIndex: number, pageSize: number): Observable<any> {
        return this.http.get(
            this.releaseNoteUrl + '/list',
            {
                // headers: new HttpHeaders(
                //     {
                //         'Content-Type': 'application/json',
                //         'Accept': 'application/json',
                //         'QonUSFrom': 'qonus_web',
                //         'QonUSApi': 'v1.0',
                //     }),
                params: new HttpParams()
                    //.set('code', this.authService.currentUser.code)
                    .set('from', 'web_admin')
                    .set('releaseType', releaseType.toString())
                    .set('startIndex', startIndex.toString())
                    .set('pageSize', pageSize.toString())
            }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }

    addReleaseNote(body: Object): Observable<any> {
        //body['storeCode'] = this.authService.currentStore.storeCode;

        return this.http.post(
            this.releaseNoteUrl + '/write',
            body,
            // {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         //'Authorization': this.authService.currentUser.code,
            //         'QonUSFrom': 'qonus_web',
            //         'QonUSApi': 'v1.0',
            //     })
            // }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }

    updateReleaseNote(id: string, body: Object): Observable<any> {
        return this.http.put(
            this.releaseNoteUrl + "/update/" + id,
            body,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.authService.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteReleaseNote(id: string): Observable<any> {
        return this.http.delete(
            this.releaseNoteUrl + "/delete/" + id,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.authService.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(map((res: any) => res))
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteFile(url: string): Observable<boolean> {
        return this.http.delete(
            MAIN.URL.FILEUPLOAD_URL + '/' + url,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.authService.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                }),
            })
            .pipe(map((res: any) => res.error_code == 0 ? true : false))
            .pipe(
                catchError(this.handleError)
            );
    }
}
