import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../shared/user/auth.service';
import { ErrorCode } from '../../shared/constant/errorcode';

import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../../shared/components/result-dialog/result-dialog.component';

@Component({
    selector: 'as-login',
    templateUrl: './login.component.html'
})
export class LoginComponent {

    loginForm: FormGroup;
    registerForm: FormGroup;

    logined: boolean = false;

    emailInvalid: boolean = false;
    passwordInvalid: boolean = false;

    static msgEmailRequired: string = "Enter email address";
    static msgPasswordRequired: string = "Enter password";
    static msgCodeRequired: string = "Enter valid code";
    static msgEmailInvalid: string = "Email is not exist";
    static msgPasswordInvalid: string = "Incorrect password";

    msgEmail: string = LoginComponent.msgEmailRequired;
    msgPassword: string = LoginComponent.msgPasswordRequired;
    msgCode: string = LoginComponent.msgCodeRequired;

    constructor(public dialog: MatDialog, public fb: FormBuilder, public authService: AuthService, private router: Router, private route: ActivatedRoute) {
        this.loginForm = fb.group({
            'email': [null, Validators.required],
            'password': [null, Validators.required]
        });

        this.registerForm = fb.group({
            'email': [null, Validators.required],
            'password': [null, Validators.required],
            'name': [null, Validators.required],
            'jobTitle': [null, Validators.required],
            'code': [null, Validators.required],
        });

        let reason: string | null = this.route.snapshot.paramMap.get('reason');
        if (reason !== undefined && reason == "401") {
            this.authService.logoutWithoutRedirect();
        }
    }


    openDialog(title: string, content: string) {
        return this.dialog.open(ResultDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

    login(value: any): void {
        this.msgEmail = LoginComponent.msgEmailRequired;
        this.msgPassword = LoginComponent.msgPasswordRequired;
        this.emailInvalid = false;
        this.passwordInvalid = false;

        this.authService.login(value.email, value.password)
            .subscribe(data => {
                if (data.error == ErrorCode.SUCCESS) {
                    if (data.error == ErrorCode.SUCCESS) {
                        console.log(this.route.snapshot);
                        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                        this.router.navigate([returnUrl]);
                    } else {
                        this.openDialog("Failed", data.message);
                    }

                }
                else if (data.error == ErrorCode.ERROR_THIRDPARTY) {
                    console.log(data.exception);
                    this.msgEmail = data.exception.message;
                    this.emailInvalid = true;
                } else {
                    this.msgEmail = data.message;
                    this.emailInvalid = true;
                }
            },
                err => {
                    this.msgEmail = "Server Error.";
                    this.emailInvalid = true;
                    console.log(err);
                });

    }

    register(value: any): void {
        this.msgEmail = LoginComponent.msgEmailRequired;
        this.msgPassword = LoginComponent.msgPasswordRequired;
        this.emailInvalid = false;
        this.passwordInvalid = false;

        this.authService.register(value.email, value.password, value.name, value.code)
            .subscribe(data => {
                if (data.error == ErrorCode.SUCCESS) {
                    this.openDialog("Success", "Successfully registered").afterClosed().subscribe(result => {
                        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                        this.router.navigate([returnUrl]);
                    });

                } else {
                    this.openDialog("Failed", data.message);
                    let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    this.router.navigate([returnUrl]);
                }
            },
                err => {
                    this.openDialog("Server Error", err);

                    console.log(err);
                });

    }

    logout(): void {
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.authService.logout(returnUrl, "login");
    }
}