import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { User } from './user';
import { MAIN } from '../main';
import { ErrorCode } from '../constant/errorcode';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {

    private authUrl = MAIN.URL.AUTH_URL;
    private userUrl = MAIN.URL.API_URL + 'user';
    currentUser: User | null = null;

    private currentUserSubject$: BehaviorSubject<User | null> = new BehaviorSubject(this.currentUser);
    readonly currentUser$: Observable<User | null> = this.currentUserSubject$.asObservable();

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }

    isLoggedIn(): boolean {
        var adminString = localStorage.getItem('currentUser')
        if (adminString == "undefined")
            return false;

        const user = JSON.parse(adminString!);
        return user !== null;
    }

    isAuthenticated(): boolean {
        return true;
    }

    constructor(private http: HttpClient, private router: Router) {
        var user = localStorage.getItem('currentUser');

        if (user != "undefined") {
            this.currentUser = JSON.parse(user!);
            this.currentUserSubject$.next(this.currentUser);
        }
    }

    login(email: string, password: string): Observable<any> {

        return this.http.post(
            this.authUrl,
            { email: email, password: password },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(map((response: any) => ((res: any) => {
                if (res.error == ErrorCode.SUCCESS) {
                    this.currentUser = res.user;
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

                } else {
                    this.currentUser = null;
                    localStorage.setItem('currentUser', JSON.stringify(null));
                }

                return res;
            })))
            .pipe(
                catchError(this.handleError)
            );
    }

    register(email: string, password: string, name: string, code: string): Observable<any> {

        return this.http.post(
            this.userUrl,
            { email: email, password: password, name: name, code: code },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': this.currentUser.code,
                    // 'QonUSFrom': 'qonus_web',
                    // 'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(map((response: any) => ((res: any) => {
                if (res.error == ErrorCode.SUCCESS) {
                    this.currentUser = res.user;
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

                } else {
                    // this.currentUser = null;
                    // localStorage.setItem('currentUser', JSON.stringify(null));
                }

                return res;
            })))
            .pipe(
                catchError(this.handleError)
            );
    }

    logoutWithoutRedirect(): void {
        this.currentUser = null;
        this.currentUserSubject$.next(null);
        localStorage.removeItem('currentUser');
    }

    logout(previousUrl: string, loginPageUrl: string): void {
        this.logoutWithoutRedirect();
        this.router.navigate([loginPageUrl], { queryParams: { returnUrl: previousUrl } });
    }
}