import { Component, ViewChild, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'as-navbar',
    templateUrl: './navbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
    @Input() brand: string;

    showOption:Boolean = false;

    constructor(private router: Router) {

    }
}
