import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-feature-queuing',
    templateUrl: './feature.queuing.component.html'
})
export class FeatureQueuingComponent {
    headerLayout: string;

    constructor(private router:Router) {
    }
}
