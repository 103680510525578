<header id="header" class="header-effect-shrink"
	data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 70}">
	<div class="header-body">
		<div class="header-container container">
			<div class="header-row">
				<div class="header-column">
					<div class="header-row">
						<div class="header-logo">
							<a href="index.html">
								<img alt="Porto" height="48" data-sticky-width="43.2" data-sticky-height="40"
									src="../assets/img/logo_green_250x270.png">
							</a>
						</div>

					</div>
				</div>
				<div class="header-column justify-content-end">
					<div class="header-row">
						<div class="header-nav header-nav-links order-2 order-lg-1">
							<div
								class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
								<nav class="collapse">
									<ul class="nav nav-pills" id="mainNav">
										<li>
											<a class="dropdown-item dropdown-toggle text-3"
												href="/home/feature/dinein">Dine-in</a>
										</li>

										<li>
											<a class="dropdown-item dropdown-toggle text-3"
												href="/home/feature/takeaway">Takeaway</a>
										</li>

										<li>
											<a class="dropdown-item dropdown-toggle text-3"
												href="/home/feature/queuing">Queuing</a>
										</li>

										<li>
											<a class="dropdown-item dropdown-toggle text-3"
												href="/home/feature/booking">Booking</a>
										</li>

										<li>
											<a class="dropdown-item dropdown-toggle text-3" href="/home/blog">BLOG</a>
										</li>

										<li>
											<a class="dropdown-item dropdown-toggle text-3"
												href="/home/releasenote">RELEASE</a>
										</li>

										<li>
											<a class="dropdown-item dropdown-toggle text-3 text-color-primary ml-lg-5"
												href="/home/contactus/faq">FAQ</a>
										</li>

										<!-- <li>
											<a class="dropdown-item dropdown-toggle text-3 text-color-tertiary"
												href="/home/pricing">Pricing</a>
										</li> -->


										<!-- <li class="dropdown">
												<a class="dropdown-item dropdown-toggle" href="#">
													Features
												</a>

												<ul class="dropdown-menu">
													<li>
														<a class="dropdown-item" href="/home/feature/queuing">
															Queuing
														</a>
													</li>
													<li>
														<a class="dropdown-item" href="/home/feature/booking">
															Booking
														</a>
													</li>
													<li>
														<a class="dropdown-item" href="/home/feature/dinein">
															Dine-in
														</a>
													</li>
													<li>
														<a class="dropdown-item" href="/home/feature/takeaway">
															Takeaway
														</a>
													</li>

												</ul>

											</li>
											<li class="dropdown">
												<a class="dropdown-item dropdown-toggle" href="#">
													Contact Us
												</a>

												<ul class="dropdown-menu">
													<li><a class="dropdown-item" href="/home/contactus/sales">Sales</a></li>
													<li><a class="dropdown-item" href="/home/contactus/support">Support</a></li>
													<li><a class="dropdown-item" href="/home/contactus/faq">FAQ</a></li>
												</ul>
                                            </li> -->
									</ul>

									<!-- <button class="btn btn-primary font-weight-semibold text-3 btn-px-3 my-auto">
                                            <a href="/home/pricing" class="text-color-light">
                                                Pricing
                                            </a>
                                        </button> -->
								</nav>
							</div>
							<button class="btn header-btn-collapse-nav" data-toggle="collapse"
								data-target=".header-nav-main nav">
								<i class="fal fa-bars"></i>
							</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</header>