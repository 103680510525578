export enum ErrorCode {
    SUCCESS,
    ERROR_NOT_FOUND,    // not_found_
    ERROR_OPERATION,    // closed_queue, invalid_ticket_no, already_in, not_available_queue, not_available_order [message:"specific message"]
    
    ERROR_SERVER,       // catch(exception) [message:"Something wrong happened! call customer support - code (1)", exception:err]
    ERROR_DATABASE,     // database exception such as data_save_error [message:"Something wrong happened! call customer support - code (2)", exception:err]
    ERROR_THIRDPARTY,   // firebase, googleAPIs [message: 3rdParty's message or "Something wrong happened! call customer support - code (3)", exception:3rd party's exception]
    ERROR_UNKNOWN,      // when don't know how to handle error [message:"Something wrong happened! call customer support - code (4)", exception:err]
    ERROR_ABORTED_BYUSER,       // user abort operation on client side
};
