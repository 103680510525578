<hr class="solid my-5">

<div class="container container-xl-custom">
    <div class="row justify-content-center featured-boxes featured-boxes-style-4 pb-3 mb-4">
        <div class="col-md-4">
            <div class="featured-box featured-box-primary">
                <div class="box-content appear-animation" data-appear-animation="fadeInUp"
                    data-appear-animation-delay="200">
                    <i class="icon-featured icons icon-location-pin mb-3"></i>
                    <span class="d-block opacity-7 line-height-1">QonUS ADDRESS</span>
                    <h2 class="font-weight-bold text-color-dark text-5 mb-0">8 help St, Chatswood, Syndey 2067
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="featured-box featured-box-primary">
                <div class="box-content appear-animation" data-appear-animation="fadeInUp"
                    data-appear-animation-delay="1000">
                    <i class="icon-featured icons icon-call-in mb-3"></i>
                    <span class="d-block opacity-7 line-height-1">CALL US NOW</span>

                    <h2 class="font-weight-bold text-4 mb-0">Sales & Support</h2>
                    <div class="col">
                        <h2 class="font-weight-bold text-color-dark text-8 mb-2" style="margin-top:-10px;">0493 528 338
                        </h2>
                    </div>
                    <h2 class="font-weight-bold text-color-dark text-8 mb-0" style="margin-top:-10px;">0414
                        318 244
                    </h2>

                    <!-- 
                    <div class="row">
                        <div class="col-2">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">Sales</h2>
                        </div>

                        <div class="col">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">0414
                                318 244
                            </h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-2">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0"></h2>
                        </div>

                        <div class="col">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">0451 905 408
                            </h2>
                        </div>
                    </div> -->
                    <!-- 
                    <div class="row mt-2">
                        <div class="col-2">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">Sydney support</h2>
                        </div>

                        <div class="col">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">0493 528 338
                            </h2>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-2">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">Qld sales support</h2>
                        </div>

                        <div class="col">
                            <h2 class="font-weight-bold text-color-dark text-5 mb-0">0478 669 237
                            </h2>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="featured-box featured-box-primary mx-5">
                <div class="box-content appear-animation" data-appear-animation="fadeInUp"
                    data-appear-animation-delay="600">
                    <i class="icon-featured icons icon-clock mb-3"></i>
                    <span class="d-block opacity-7 line-height-1">BUSINESS HOURS</span>
                    <h2 class="font-weight-bold text-color-dark text-5 mb-0">Mon - Sat / 10:00AM - 6:00PM</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<hr>


<footer id="footer" class="bg-color-light border-0 pt-5 mt-0">
    <div class="container pb-5 mb-5">
        <div class="row justify-content-center">
            <div class="col-md-9 col-lg-6">
                <h2 class="font-weight-normal text-color-dark text-center text-8 mb-4"><strong
                        class="font-weight-extra-bold">Contact</strong> Us</h2>
                <div class="text-4 opacity-8 text-center">Please leave us a message about anything you want to know.
                </div>
                <div class="text-4 opacity-8 text-center mt-0 mb-4">We'll be with you soon.</div>
                <form class="contact-form form-style-3" #registerForm="ngForm" (ngSubmit)="inquiry()" role="form">
                    <div class="contact-form-success alert alert-success d-none">
                        Message has been sent to us.
                    </div>

                    <div class="contact-form-error alert alert-danger d-none">
                        Error sending your message.
                        <span class="mail-error-message text-1 d-block"></span>
                    </div>

                    <input type="hidden" value="Contact Form" name="subject" id="subject">
                    <div class="form-row">
                        <div class="form-group col-md-6 pr-md-2">
                            <input type="text" value="" data-msg-required="Please enter your name." maxlength="100"
                                class="form-control h-auto py-2" placeholder="Your Name..." name="name" id="name"
                                [(ngModel)]="name" required>
                        </div>
                        <div class="form-group col-md-6 pl-md-2">
                            <input type="text" value="" data-msg-required="Please enter your phone." maxlength="100"
                                class="form-control h-auto py-2" placeholder="Your Phone..." name="phone" id="phone"
                                [(ngModel)]="phone" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" value="" data-msg-required="Please enter your email address."
                            data-msg-email="Please enter a valid email address." maxlength="100"
                            class="form-control h-auto py-2" placeholder="Your Email Address..." name="email" id="email"
                            [(ngModel)]="email">
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <textarea maxlength="5000" data-msg-required="Please enter your message." rows="4"
                                class="form-control" placeholder="Your Message..." name="message" id="message"
                                [(ngModel)]="message"></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col text-center">
                            <input type="submit" value="SUBMIT"
                                class="btn btn-primary font-weight-semibold text-3 px-5 btn-py-2"
                                data-loading-text="Loading...">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="footer-copyright curved-border curved-border-top d-flex align-items-center">
        <div class="container py-2">
            <div class="row py-4">
                <div class="col text-center">
                    <p class="text-3"><strong class="font-weight-normal text-color-light opacity-7">© 2020 Multiz
                            Corp,. ltd - Copyright. All Rights Reserved.</strong></p>
                </div>
            </div>
        </div>
    </div>
</footer>