import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardAdmin implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn() == true) {

            if (this.authService.currentUser?.role == 'user')
                return true;

            this.router.navigate(['/home', { reason: '401' }], { queryParams: { returnUrl: state.url } });
            return false;
        }

        this.router.navigate(['/login', { reason: '401' }], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

@Injectable()
export class AuthGuardManager implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn() == true) {
            if (this.authService.currentUser?.role == 'user')
                return true;
        }

        this.router.navigate(['/login', { reason: '401' }], { queryParams: { returnUrl: state.url } });
        return false;
    }
}