import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { throwError } from 'rxjs';

import { MAIN } from '../../main';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';

@Injectable()
export class QonUSService {
    private qonusUrl = MAIN.URL.API_URL + 'qonus';

    constructor(private http: HttpClient) {
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }

    inquiry(body: Object): Observable<any> {
        return this.http
            .post(this.qonusUrl + "/inquiry", body,
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'QonUSFrom': 'qonus_home_web',
                        'QonUSApi': 'v1.0',
                    })
                }
            )
            .pipe(catchError((error: any) => throwError(error.json().error || 'Server Error')));
    }

    salesContact(body: Object): Observable<any> {
        return this.http
            .post(this.qonusUrl + "/salescontact", body,
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'QonUSFrom': 'qonus_home_web',
                        'QonUSApi': 'v1.0',
                    })
                }
            )
            .pipe(catchError((error: any) => throwError(error.json().error || 'Server Error')));
    }

    ip(): Observable<any> {
        return this.http.get("https://api.ipify.org/?format=json");
    }

    visitor(ip: String): Observable<any> {
        return this.http.post(this.qonusUrl + "/visitor",
            {
                'ip': ip,
            },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'QonUSFrom': 'qonus_home_web',
                    'QonUSApi': 'v1.0',
                })
            }
        )
            .pipe(catchError((error: any) => throwError(error.json().error || 'Server Error')));


    }
}