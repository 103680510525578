declare const require: any;
import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

@Component({
  selector: 'app-contactus-faq',
  templateUrl: './contactus.faq.component.html',
  styleUrls: ['./contactus.faq.component.css']
})
export class ContactUSFaqComponent {

  constructor() {
  }

  // ngAfterViewInit(): void {
  //   alert('ok2');
  //   import('../../../../js/theme.js');
  //   import('../../../../js/theme.init.js');

  //   (<any>$('#myToggle')).themePluginToggle({
  //   });

  // }

}
