import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QonUSService } from '../../shared/services/qonus/qonus.service';
@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html'
})
export class PricingComponent {
    headerLayout: string;

    constructor(private router:Router, private qonusService: QonUSService) {
    }

    ngOnInit(): void {
        this.qonusService.ip().subscribe(ip =>{
            this.qonusService.visitor(ip.ip).subscribe(data => {}, err=>{ console.log(err)});

        }, err => {console.log(err)});
    }
}
