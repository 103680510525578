import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-feature-dinein',
    templateUrl: './feature.dinein.component.html',
    styleUrls: ['./feature.dinein.component.css']
})
export class FeatureDineInComponent {
    headerLayout: string = '';

    constructor(private router: Router) {
    }
}
