<style>
	.vertical-center {
		margin: 0;
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
</style>


<div class="loading-overlay">
	<div class="bounce-loader">
		<div class="bounce1"></div>
		<div class="bounce2"></div>
		<div class="bounce3"></div>
	</div>
</div>

<div class="body">
	<div role="main" class="main">



		<div class="owl-carousel-wrapper" style="height: 500px;">
			<div class="owl-carousel dots-inside dots-horizontal-center show-dots-hover dots-light nav-style-1 nav-inside nav-inside-plus nav-dark nav-lg nav-font-size-lg show-nav-hover mb-0"
				data-plugin-options="{'responsive': {'0': {'items': 1}, '479': {'items': 1}, '768': {'items': 1}, '979': {'items': 1}, '1199': {'items': 1}}, 'loop': false, 
                'autoHeight': false, 'margin': 0, 'dots': true, 'dotsVerticalOffset': '-35px', 'nav': false, 'animateIn': 'fadeIn', 'animateOut': 'fadeOut', 
                'mouseDrag': false, 'touchDrag': false, 'pullDrag': false, 'autoplay': true, 'autoplayTimeout': 9000, 'autoplayHoverPause': true, 'rewind': true}">

				<!-- Carousel Slide 1 -->
				<div id="slide1" class="position-relative overlay overlay-show overlay-op-8"
					data-dynamic-height="['650px','650px','650px','550px','500px','300px']"
					style="background-image: url(../assets/img/slides/slide_back_4.png); background-size: cover; background-position: center; height: 500px;">
					<div class="container position-relative z-index-3 h-100">
						<div class="row justify-content-center align-items-center h-100">
							<div class="col-lg-7 text-center">
								<div class="d-flex flex-column align-items-center justify-content-center h-100">
									<h1 class="text-color-light font-weight-extra-bold text-12-13 line-height-1 line-height-sm-3 mb-2 appear-animation"
										data-appear-animation="blurIn" data-appear-animation-delay="500"
										data-plugin-options="{'minWindowWidth': 0}">QonUS</h1>
									<p class="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-0"
										data-plugin-animated-letters
										data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 30}">
										We do believe</p>
									<p class="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-5"
										data-plugin-animated-letters
										data-plugin-options="{'startDelay': 2000, 'minWindowWidth': 0, 'animationSpeed': 30}">
										Happy customers are the best sales</p>
									<!-- <a href="/home/contactus/sales"
										class="btn btn-light btn-outline text-color-light text-color-hover-dark font-weight-bold text-3 btn-px-5 py-3 appear-animation"
										data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1800"
										data-plugin-options="{'minWindowWidth': 0}"><span
											class="font-weight-regular text-5">FREE 30 DAY TRIAL</span><br><span
											class="font-weight-regular text-2">No Lock-in Contracts</span></a> -->
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Carousel Slide 2 -->
				<div id="slide2" class="position-relative overlay overlay-show overlay-op-7"
					data-dynamic-height="['650px','650px','650px','550px','500px','300px']"
					style="background-image: url(../assets/img/slides/slide_back_3.png); background-size: cover; background-position: center; height: 500px;">
					<div class="container position-relative z-index-3 h-100">
						<div class="row justify-content-center align-items-center h-100">
							<div class="col-lg-7 text-center">
								<div class="d-flex flex-column align-items-center justify-content-center h-100">
									<h3 class="position-relative text-color-light text-5 line-height-5 font-weight-medium ls-0 px-4 mb-2 appear-animation"
										data-appear-animation="fadeInDownShorterPlus"
										data-plugin-options="{'minWindowWidth': 0}">
										Services we provide
									</h3>
									<h1 class="text-color-light font-weight-extra-bold text-12-13 line-height-1 line-height-sm-3 mb-1 appear-animation"
										data-appear-animation="blurIn" data-appear-animation-delay="500"
										data-plugin-options="{'minWindowWidth': 0}">Dine-in, Takeaway</h1>
									<h1 class="text-color-light font-weight-extra-bold text-12-13 line-height-1 line-height-sm-3 mb-3 appear-animation"
										data-appear-animation="blurIn" data-appear-animation-delay="1500"
										data-plugin-options="{'minWindowWidth': 0}">Booking and Queue management</h1>



									<!-- <p class="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-5" data-plugin-animated-letters data-plugin-options="{'startDelay': 3000, 'minWindowWidth': 0, 'animationSpeed': 30}">Trusted by over 40,000 satisfied users.</p> -->
									<!-- <a href="/home/contactus/sales"
										class="btn btn-light btn-outline text-color-light text-color-hover-dark font-weight-bold text-2 btn-px-5 py-3 appear-animation"
										data-appear-animation="fadeInUpShorter" data-appear-animation-delay="3300"
										data-plugin-options="{'minWindowWidth': 0}"><span
											class="font-weight-regular text-5">FREE 30 DAY TRIAL</span><br><span
											class="font-weight-regular text-2">No Lock-in Contracts</span></a> -->
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<!-- <div clas="vertical-center"
			style="font-size:20px; height:180px; justify-content: center; display : flex; align-items: center; font-weight: 600; flex-direction: column;">

			<div class="mb-2"
				style="color:navy; font-size:25px; margin-left: 10px; margin-right:10px; font-weight: 800;">
				기술지원 및 마케팅 직원 채용
			</div>
			<div class="mb-3" style="color:darkslateblue; font-size:21px; margin-left: 10px; margin-right:10px;">
				큰 꿈과 비전을 가지고 있는 <span style="font-weight: 900;">인재를 모집합니다</span>
			</div>


			<div class="mb-1" style="color:black">
				<i class="mr-2 far fa-envelope"></i> admin@qonus.com.au
			</div>

			<div class="mr-2" style="color:black;">
				<i class="mr-2 far fa-phone-alt"></i> 0451 905 408
			</div>

		</div> -->


		<section class="section section-primary section-no-border section-height-3 my-0 appear-animation"
			data-appear-animation="fadeIn">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 appear-animation mb-5" data-appear-animation="fadeInRightShorter"
						data-appear-animation-delay="300">

						<h2 class="font-weight-bold line-height-1 text-7 negative-ls-1 mb-3">The Best All-In-One system
							for Restaurants</h2>
						<p class="text-color-light opacity-8 text-4 mb-4">QonUS is an application integrating restaurant
							table management, ordering, POS, and queuing into a single platform. We've worked hard
							making things simple so you can focus on running your business. We've simplified how you and
							your customer interacts ensuring everyone has an exceptional experience.</p>

						<!-- <a href="/home/pricing"
							class="btn btn-dark font-weight-semibold btn-px-5 btn-py-2 text-2 rounded-0">PRICING</a> -->
					</div>
					<div class="col-lg-12 mt-5 mt-lg-0">
						<div class="Row">
							<h4 class="line-height-1 negative-ls-1 mb-3" data-appear-animation="fadeInRightShorter"
								data-appear-animation-delay="100">With QonUS we've taken the pain out of ...</h4>
						</div>
						<div class="row">
							<div class="col-lg-6 mb-4 mb-lg-5">
								<div class="feature-box feature-box-style-2 appear-animation"
									data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
									<div class="feature-box-icon">
										<i class="fas fa-utensils text-color-light"></i>
									</div>
									<div class="feature-box-info">
										<h4 class="font-weight-bold text-color-light mb-2">Dine-in</h4>
										<p class="text-color-light opacity-8 mb-0">Don't pay for expensive table
											management systems</p>
										<p class="text-color-light opacity-8 mb-0">Stop paying staff for taking table
											orders</p>

										<p class="text-color-light opacity-8 mb-0">No more misunderstandings on orders
										</p>
										<p class="text-color-light opacity-8 mb-0">No more Paper menus</p>
										<p class="text-color-light opacity-8 mb-0">Stop paying for expensive menu
											updates</p>

									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="feature-box feature-box-style-2 appear-animation"
									data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">
									<div class="feature-box-icon">
										<i class="fas fa-shopping-bag text-color-light"></i>
									</div>
									<div class="feature-box-info">
										<h4 class="font-weight-bold text-color-light mb-2">Takeaway</h4>
										<p class="text-color-light opacity-8 mb-0">Don't pay high commissions for
											takeaway orders</p>
										<p class="text-color-light opacity-8 mb-0">Stop missing takeaway orders due to
											not answering the phone</p>
										<p class="text-color-light opacity-8 mb-0">Stop paying staff for taking orders
											through phone calls</p>
										<p class="text-color-light opacity-8 mb-0">No more misunderstandings on orders
										</p>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-6 mb-lg-0 appear-animation" data-appear-animation="fadeInLeftShorter"
								data-appear-animation-delay="600">
								<div class="feature-box feature-box-style-2">
									<div class="feature-box-icon">
										<i class="fas fa-user-friends text-color-light"></i>
									</div>
									<div class="feature-box-info">
										<h4 class="font-weight-bold text-color-light mb-2">Queuing</h4>
										<p class="text-color-light opacity-8 mb-0">Stop paying for staff to manage the
											queue</p>
										<p class="text-color-light opacity-8 mb-0">Stop losing customers due to long
											queue and misunderstandings</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6 appear-animation" data-appear-animation="fadeInLeftShorter"
								data-appear-animation-delay="800">
								<div class="feature-box feature-box-style-2">
									<div class="feature-box-icon">
										<i class="fas fa-book-open text-color-light"></i>
									</div>
									<div class="feature-box-info">
										<h4 class="font-weight-bold text-color-light mb-2">Booking</h4>
										<p class="text-color-light opacity-8 mb-0">Stop missing on restaurant bookings
										</p>
										<p class="text-color-light opacity-8 mb-0">Paying staff for taking bookings
											through phone calls</p>
										<p class="text-color-light opacity-8 mb-0">Special requests from the diners? No
											worries. That's why we are here</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>



		<!-- <section class="section section-no-border section-height-3 my-0 appear-animation"
			data-appear-animation="fadeIn">
			<div class="container">
                <div class="row mb-3">
                    <div class="col">
						<div class="videoWrapper">
							<iframe src="https://player.vimeo.com/video/498154863" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
						</div>
					</div>
				</div>
				<div class="row mb-3">
                    <div class="col">
						<div class="videoWrapper">
							<iframe src="https://player.vimeo.com/video/499890882" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
						</div>
					</div>
				</div>
				<div class="row mb-3">
                    <div class="col">
						<div class="videoWrapper">
							<iframe src="https://player.vimeo.com/video/499891444" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
						</div>
					</div>
                </div>
				<div class="row mb-3">
					<div class="col-sm-6 mb-3 mb-lg-0">
						<div class="videoWrapper">
							<iframe src="https://www.youtube.com/embed/2ysSGjVsDn8" frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen></iframe>

						</div>
						
					</div>
					<div class="col-sm-6">
						<div class="videoWrapper">
						<iframe src="https://www.youtube.com/embed/X6e_UySfY2c" frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen></iframe>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-6 mb-3 mb-lg-0">
						<div class="videoWrapper">
							<iframe src="https://www.youtube.com/embed/65r71wBiBbY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

						</div>
						
					</div>
					<div class="col-sm-6">
						<div class="videoWrapper">
							<iframe src="https://www.youtube.com/embed/iZIVoes7p1k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</div>
		</section> -->

		<div class="container">
			<hr class="solid my-5">

			<div class="row justify-content-center">
				<div class="col">

					<!-- <img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/web_based.png"
						alt="layout styles" /> -->

					<picture>

						<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
							srcset="../assets/img/web_based_mobile.png 600w, ../assets/img/web_based.png"
							alt="Web Based QonUS">
					</picture>
				</div>

			</div>

		</div>


		<div class=" container">
			<hr class="solid my-5">

			<div class="row align-items-center pt-2 pl-5 pr-5 appear-animation"
				data-appear-animation="fadeInLeftShorter">
				<div class="col-md-12">
					<h2 class="col font-weight-normal text-6 pl-2"><strong class="font-weight-extra-bold">QonUS</strong>
						Business - iOS / Android
					</h2>
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_1.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_2.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_3.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_4.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_5.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_6.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_7.png" alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4"
						src="../assets/img/product/qonus_business_8.png" alt="layout styles" />
				</div>
			</div>

			<hr class="solid my-5">

			<div class="row align-items-center pt-2 pl-5 pr-5 appear-animation"
				data-appear-animation="fadeInLeftShorter">
				<div class="col-md-12">
					<h2 class="col font-weight-normal text-6 pl-2"><strong class="font-weight-extra-bold">QonUS</strong>
						Menupad - iOS / Android
					</h2>
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_1.png"
						alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_2.png"
						alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_3.png"
						alt="layout styles" />
				</div>
				<div class="col-md-3 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_4.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-3 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_vert_1.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-3 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_vert_2.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-3 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/menupad_vert_3.png"
						alt="layout styles" />
				</div>

			</div>

			<hr class="solid my-5">

			<div class="row align-items-center pt-2 pl-5 pr-5 appear-animation"
				data-appear-animation="fadeInLeftShorter">
				<div class="col-md-12">
					<h2 class="col font-weight-normal text-6 pl-2"><strong class="font-weight-extra-bold">QonUS</strong>
						Customer - Web</h2>
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_web_1.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_web_2.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_web_3.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_web_4.png"
						alt="layout styles" />
				</div>
			</div>

			<hr class="solid my-5">

			<div class="row align-items-center pt-2 pl-5 pr-5 appear-animation"
				data-appear-animation="fadeInLeftShorter">
				<div class="col-md-12">
					<h2 class="col font-weight-normal text-6 pl-2"><strong class="font-weight-extra-bold">QonUS</strong>
						Customer - iOS / Android</h2>
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_screen_1.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_screen_2.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_screen_3.png"
						alt="layout styles" />
				</div>
				<div class="col-md-2 mb-4 mb-md-0">
					<img class="img-fluid scale-1 pl-2 pr-2 pr-md-0 my-4" src="../assets/img/product/qonus_screen_4.png"
						alt="layout styles" />
				</div>

				<div class="row align-items-center pt-2 pl-5 pr-5 appear-animation"
					data-appear-animation="fadeInLeftShorter">

					<!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
					(init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
					(beforeChange)="beforeChange($event)">
					<div ngxSlickItem *ngFor="let slide of slides" class="slide">
						<img src="https://picsum.photos/536/354?{{slide}}" alt="" width="100%">
					</div>
				</ngx-slick-carousel> -->
				</div>
			</div>

			<!-- <div style="margin-top:130px; font-size: 50px; color:black; font-weight: 700;">
				Do you need the more betterer solution?
			</div>
			<div style="margin-top:50px; font-size: 30px">
				If you do just contact us,
			</div>
			<div style="margin-top:20px; font-size: 30px">
				<span style="font-weight:600; font-size:36px; color:red;">no cost</span>
				at all until you decide to go
				with US!
			</div> -->

			<div class="row align-items-center pt-5 pl-5 pr-5 appear-animation"
				data-appear-animation="fadeInLeftShorter">
				<div class="col-md-12 pt-5">
					<h2 class="col font-weight-normal text-6 pl-2">QonUS<strong class="font-weight-extra-bold">
							Partners</strong>
					</h2>
				</div>
			</div>

			<img class="w-100" src="../assets/img/partner/logo_all.png" alt="customer logo" />

			<!-- <div class="slick">
				<div>
					<img style="width:120px;" src="../assets/img/partner/17.basax.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/8.mangococo.png" alt="customer logo" />
				</div>
				<div>
					<img style="height:120px;" src="../assets/img/partner/4.pochawang.png" alt="customer logo" />
				</div>
				<div>
					<img style="height:120px;" src="../assets/img/partner/5.keeptone.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/2.pbs.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/colddrip.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/58.zen.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/60.sushifirst.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/61.shigoljip.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/62.windsorthai.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/warasushi.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/64.mukbang888.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/65.peckingvillage.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/baodao.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/1.konnichiwa.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/7.charlis.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/18.sushingrill.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/10.phou.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/qubeonbay.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/3.gogiwang.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/basax_melrosepark.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/gogiae.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/cheesykebabs_stleonards.png"
						alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/13.yolk.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/15.saikyo.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/14.sopoong.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/16.tomiskitchen.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/19.sushikaido.png" alt="customer logo" />
				</div>

				<div>
					<img style="width:120px;" src="../assets/img/partner/22.sushiru.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/23.prontocafe.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/25.ginzaip.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/31.dinggu.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/32.1606luis.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/29.khaokang.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/27.khaosan.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/37.noonane.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/28.sushiyama.png" alt="customer logo" />
				</div>
			</div>

			<div class="slick2">
				<div>
					<img style="width:120px;" src="../assets/img/partner/36.toktok.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/57.gangnampocha.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/38.kcbw.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/41.kcns.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/40.oldgardenkaffee.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/68.888chicken.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/73.zhangchef.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/74.woowol.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/77.bensburgers.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/88.sushihouse.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/89.sanwiye.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/96.sushiworld.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/98.eatem.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/99.alphasushi.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/101.dynamite.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/102.mokkoji.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/56.threetimesthai.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/78.mikirin.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/79.myeongdong.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/80.shizen.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/44.cafezozo.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/45.oceantucker.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/87.chickenofrock.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/46.fatfingers.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/105.bombom.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/106.Sushiya.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/30.thairiffic.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/39.greenlite.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/47.sushiyogi.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/48.norisushi.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/49.sushiarai.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/50.kbbq.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/51.guthai.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/52.oppane.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/diplomat.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/55.charcoalhouse.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/59.kaokao.png" alt="customer logo" />
				</div>
				<div>
					<img style="width:120px;" src="../assets/img/partner/33.chinel.png" alt="customer logo" />
				</div>

			</div> -->
		</div>

	</div>

</div>



<router-outlet></router-outlet>