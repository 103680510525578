export const MAIN = {
    APP: {
        BRAND: 'QonUS'
    },
    URL: {
        // API_URL: 'https://qr.qonus.com.au:30001/api/',
        // AUTH_URL: 'https://qr.qonus.com.au:30001/auth/',
        // FILEUPLOAD_URL: 'https://qr.qonus.com.au:30001/fileupload'

        API_URL: 'https://hsapi.qonus.com.au/api/',
        AUTH_URL: 'https://hsapi.qonus.com.au/auth/',
        FILEUPLOAD_URL: 'https://hsapi.qonus.com.au/fileupload'

        // API_URL: 'http://localhost:9001/api/',
        // AUTH_URL: 'http://localhost:9001/auth/',
        // FILEUPLOAD_URL: 'http://localhost:9001/fileupload'
    }
};

export const ENV = {
    DATETIME: { FORMAT: "yyyy/MM/dd hh:mm a" }
};