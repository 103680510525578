export class ReleaseNote {
    _id: string;

    releaseType: number;
    version: string;

    title: string;
    detail: string;
    author: string;

    createdTime: Date;
    modifiedTime: Date;
}
