<div role="main" class="main">

    <section class="page-header page-header-classic">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active">Comparison with</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="container py-2">

        <ul class="nav nav-pills sort-source sort-source-style-3 justify-content-center" data-sort-id="portfolio" data-option-key="filter" data-plugin-options="{'layoutMode': 'fitRows', 'filter': '*'}">
            <li class="nav-item active" data-option-value="*"><a class="nav-link text-1 text-uppercase active" href="#">Show All</a></li>
            <li class="nav-item" data-option-value=".dinein"><a class="nav-link text-1 text-uppercase" href="#">Dine-in</a></li>
            <li class="nav-item" data-option-value=".takeaway"><a class="nav-link text-1 text-uppercase" href="#">Takeaway</a></li>
            <li class="nav-item" data-option-value=".queuing"><a class="nav-link text-1 text-uppercase" href="#">Queuing</a></li>
            <li class="nav-item" data-option-value=".booking"><a class="nav-link text-1 text-uppercase" href="#">Booking</a></li>
            <li class="nav-item" data-option-value=".pos"><a class="nav-link text-1 text-uppercase" href="#">POS</a></li>
        </ul>

        <div class="sort-destination-loader sort-destination-loader-showing mt-3">
            <div class="row portfolio-list sort-destination" data-sort-id="portfolio">
                

                <div class="col-lg-12 isotope-item mt-4 dinein">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <img src="img/projects/project-short.jpg" class="img-fluid border-radius-0" alt="">

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">QonUS Dine-in</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> Nov 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 dinein">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <img src="img/projects/project-short.jpg" class="img-fluid border-radius-0" alt="">

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">Me&U</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> Nov 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 takeaway">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <span class="owl-carousel owl-theme dots-inside m-0" data-plugin-options="{'items': 1, 'margin': 20, 'animateOut': 'fadeOut', 'autoplay': true, 'autoplayTimeout': 3000}"><span><img src="img/projects/project-1-short.jpg" class="img-fluid border-radius-0" alt=""></span><span><img src="img/projects/project-1-short-2.jpg" class="img-fluid border-radius-0" alt=""></span></span>

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">QonUS Takeaway</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> Jan 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 takeaway">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <span class="owl-carousel owl-theme dots-inside m-0" data-plugin-options="{'items': 1, 'margin': 20, 'animateOut': 'fadeOut', 'autoplay': true, 'autoplayTimeout': 3000}"><span><img src="img/projects/project-1-short.jpg" class="img-fluid border-radius-0" alt=""></span><span><img src="img/projects/project-1-short-2.jpg" class="img-fluid border-radius-0" alt=""></span></span>

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">Uber Eats</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> Jan 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 takeaway">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <span class="owl-carousel owl-theme dots-inside m-0" data-plugin-options="{'items': 1, 'margin': 20, 'animateOut': 'fadeOut', 'autoplay': true, 'autoplayTimeout': 3000}"><span><img src="img/projects/project-1-short.jpg" class="img-fluid border-radius-0" alt=""></span><span><img src="img/projects/project-1-short-2.jpg" class="img-fluid border-radius-0" alt=""></span></span>

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">Deliveroo</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> Jan 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 queuing">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <img src="img/projects/project-2-short.jpg" class="img-fluid border-radius-0" alt="">

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">QonUS Queuing</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> July 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 booking">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <img src="img/projects/project-27-short.jpg" class="img-fluid border-radius-0" alt="">

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">QonUS Booking</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> February 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-12 isotope-item mt-4 booking">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <div class="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span class="thumb-info thumb-info-no-zoom thumb-info-lighten border-radius-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="100">
                                        <span class="thumb-info-wrapper border-radius-0">
                                            <img src="img/projects/project-27-short.jpg" class="img-fluid border-radius-0" alt="">

                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-plus"></i></span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">

                            <div class="overflow-hidden">
                                <h2 class="text-color-dark font-weight-bold text-5 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600">Open Table</h2>
                            </div>

                            <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Suspendisse potenti.</p>

                            <ul class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Client:</strong> Okler Themes</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Date:</strong> February 2018</li>
                                <li><i class="fas fa-caret-right left-10"></i> <strong class="text-color-primary">Skills:</strong> <a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">DESIGN</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">BRAND</a><a href="#" class="badge badge-dark badge-sm badge-pill px-2 py-1 ml-1">WEBSITES</a></li>
                            </ul>

                        </div>
                        
                    </div>
                </div>

                
            </div>
        </div>

    </div>

</div>