<div role="main" class="main">

    <section class="page-header page-header-classic">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active">Sales</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="container py-4">

        <div class="row">
            <div class="col">

                <h2 class="font-weight-normal text-7 mb-2">Talk to <strong class="font-weight-extra-bold">a specialist</strong></h2>
                <div class="overflow-hidden">
                    <p class="mb-0">Grab a great chance to skyrocket your sales.</p>
                </div>
                
                <hr class="solid my-4">
                
                <form class="contact-form form-style-3" #registerForm="ngForm" (ngSubmit)="salesContact()" role="form">
                    <div class="row">
                        <div class="col-lg-6">
                            <h4 class="font-weight-normal text-4 mb-2">Restaurant information</h4>

                            <div class="form-group row">
                                <div class="col">
                                    <input class="form-control" type="text" name="restaurantName" [(ngModel)]="restaurantName" placeholder="Restaurant name" required>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col">
                                    <input class="form-control" type="text" name="restaurantAddressStreet" [(ngModel)]="restaurantAddressStreet" placeholder="Building number and Street name" required>
                                </div>
                            </div>    

                            <div class="form-group row">
                                <div class="col-lg-6 pr-lg-0 mb-2 mb-lg-0">
                                    <input class="form-control" type="text" name="restaurantAddressSuburb" [(ngModel)]="restaurantAddressSuburb" placeholder="Suburb" required>
                                </div>
                                <div class="col-lg-6">
                                    <input class="form-control" type="text" name="restaurantAddressPostCode" [(ngModel)]="restaurantAddressPostCode" placeholder="Postcode" required>
                                </div>
                            </div>    

                            <div class="form-group row">
                                <div class="col">
                                    <!-- <input class="form-control" type="text" name="addressDetail" placeholder="Country" required> -->

                                    <div>
                                        <select id="country" class="form-control" name="country" size="0">
                                            <option value="Australia">Australia</option>
                                        </select>
                                    </div>
                                </div>
                            </div>    

                            <h4 class="font-weight-normal text-4 mt-4 mb-2">Contact details</h4>

                            <div class="form-group row">
                                <div class="col-lg-6 pr-lg-0 mb-2 mb-lg-0">
                                    <input class="form-control" type="text" name="firstName" [(ngModel)]="firstName" placeholder="First name" required>
                                </div>
                                <div class="col-lg-6">
                                    <input class="form-control" type="text" name="lastName" [(ngModel)]="lastName" placeholder="Last name" required>
                                </div>
                            </div>    

                            <div class="form-group row">
                                <div class="col">
                                    <input class="form-control" type="text" name="phone" [(ngModel)]="phone" placeholder="Phone number (+61...)" required>
                                </div>
                            </div>    

                            <div class="form-group row">
                                <div class="col-lg-4">
                                    <input type="submit" value="SUBMIT" class="btn btn-primary font-weight-semibold text-3 px-5 btn-py-2"
                                data-loading-text="Loading...">
                                </div>
                            </div>    

                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <img class="img-fluid scale-1 pl-5 pr-5 my-4" src="../assets/img/business_partner.png"
						alt="layout styles" />
                            </div>
                            
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>