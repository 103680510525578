<style>
    .mat-tab-label {
        font-family: "Rubik";
        text-align: center;
        width: 150px;
        background-color: transparent;
        color: black;
        font-weight: 500;
        font-size: 16px;
        text-decoration: none !important;
    }

    /* Styles for the active tab label */
    .mat-tab-label.mat-tab-label-active {
        padding: 5px;
        background-color: transparent;
        color: #052957;
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
    }

    /* Styles for the ink bar */
    .mat-ink-bar {
        background-color: green;
    }
</style>

<section *ngIf="directAccess == false" class="page-header page-header-classic">
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li class="active">ReleaseNote</li>
                </ul>
            </div>
            <div class="col-auto float-right">
                <a href="/home/releasenoteposting">Write release note</a>

                <a *ngIf="loggedin" href="" (click)="logout()" class="ml-3 text-white">Logout</a>

            </div>
        </div>
        <!-- <div class="row">
            <div class="col p-static">
                <h1 data-title-border>FAQ</h1>

            </div>
        </div> -->
    </div>
</section>

<section *ngIf="directAccess == true" style="background-color: black; color: white;">
    <div class="d-flex flex-row">

        <div class="ml-5 p-1 flex-grow-1">
            <ul class="breadcrumb">
                <li><a href="#">Home</a></li>
                <li class="active">ReleaseNote</li>
            </ul>
        </div>
        <div class="p-1 mr-5" style="font-size: 0.8em;">
            <a href="/releasenote">Show all release</a>
        </div>

        <!-- <div class="row">
            <div class="col p-static">
                <h1 data-title-border>FAQ</h1>

            </div>
        </div> -->
    </div>
</section>

<div class="container py-4">

    <nav *ngIf="directAccess == false" mat-tab-nav-bar mat-align-tabs="center">
        <a class="mat-tab-label" mat-tab-link (click)="select('0')" [active]="activeLink == '0'">Business App</a>
        <a class="mat-tab-label" mat-tab-link (click)="select('1')" [active]="activeLink == '1'">User App</a>
        <a class="mat-tab-label" mat-tab-link (click)="select('2')" [active]="activeLink == '2'">Web Order Page</a>
    </nav>

    <div class="row mt-5">
        <div class="col">
            <div class="blog-posts single-post">
                <div *ngIf="releaseNotes !=
                 null && releaseNotes.length == 0" class="row">
                    <div class="col"></div>
                    <div class="col-auto"><span style="font-size: 16px;">No Release Notes</span></div>
                    <div class="col"></div>

                </div>

                <article #articlePosts *ngFor="let releaseNote of releaseNotes; let i= index"
                    class="mb-5 post post-large blog-single-post">

                    <!-- <div class="post-image ml-0">
                            <a href="blog-post.html">
                                <img src="img/blog/wide/blog-11.jpg" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="" />
                            </a>
                        </div> -->

                    <!-- <div class="post-date ml-0">
                        <span class="day">{{releaseNote.createdTime | date:'d'}}</span>
                        <span class="month">{{releaseNote.createdTime | date:'LLL'}}</span>
                    </div> -->

                    <div class="post-content ml-0 mb-5">
                        <div class="font-weight-semi-bold" style="font-size: 18px;"><a
                                href="blog-post.html">{{releaseNote.title}}</a>
                        </div>

                        <div class="post-meta">
                            <span><i class="far fa-clock"></i> By {{releaseNote.createdTime |
                                wtDateV2}}</span>
                            <!-- <span><i class="far fa-comments ml-1"></i> <a href="javascript:void(0)">12 Comments</a></span> -->

                            <span><a href="javascript:void(0)" (click)="copyLink()"><i
                                        class="fal fa-clipboard ml-2"></i> Copy link</a></span>


                            <div *ngIf="loggedin" class="mb-1">
                                <span><a [routerLink]="['/home/releasenoteposting']"
                                        [queryParams]="{releaseType: releaseNote.releaseType, version: releaseNote.version}"><i
                                            class="far fa-edit"></i>&nbsp;Edit </a></span>
                                <span><a href="javascript:void(0)"
                                        (click)="delete(releaseNote._id, releaseNote.title)"><i
                                            class="far fa-trash-alt ml-3"></i>&nbsp;Delete</a></span>
                            </div>
                        </div>

                        <div id="{{'releasenote_' + i}}" [innerHtml]="releaseNote.detail"></div>
                    </div>
                </article>

            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="text-center">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" href="javascript:void(0);" (click)="pageChange(0)"><i
                            class="fa fa-angle-double-left"></i></a></li>
                <li class="page-item"><a class="page-link" href="javascript:void(0);"
                        (click)="pageChange(currentPageIndex-1)"><i class="fa fa-angle-left"></i></a></li>
                <li class="page-item" *ngFor="let page of pages" [ngClass]="{active:currentPageIndex==page.pageIndex}">
                    <a class="page-link" href="javascript:void(0);"
                        (click)="pageChange(page.pageIndex)">{{page.pageIndex+1}}</a>
                </li>
                <!--<li><a href="#">1</a></li>
                                        <li class="active"><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>-->
                <li class="page-item"><a class="page-link" href="javascript:void(0);"
                        (click)="pageChange(currentPageIndex+1)"><i class="fa fa-angle-right"></i></a></li>
                <li class="page-item"><a class="page-link" href="javascript:void(0);"
                        (click)="pageChange(pages.length-1)"><i class="fa fa-angle-double-right"></i></a></li>
            </ul>
        </div>
    </div>
</div>