import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-contactus-support',
  templateUrl: './contactus.support.component.html',
  styleUrls: ['./contactus.support.component.css']
})
export class ContactUSSupportComponent {
  
    

}
