import { Component, Input, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { PostService } from '../../../shared/services/post/post.service';
import { AuthService } from '../../../shared/user/auth.service';
import { Post } from '../../../shared/services/post/post';

import { MAIN } from '../../../shared/main';
import { ErrorCode } from '../../../shared/constant/errorcode';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../../../shared/components/result-dialog/result-dialog.component';
import { ReleaseNote } from 'src/app/shared/services/post/releasenote';

@Component({
    selector: 'as-release-posting',
    templateUrl: './release.posting.component.html'
})
export class ReleasePostingComponent {

    @ViewChild('registerForm') registerForm: any;

    mode: string = "Register";
    releaseNote: ReleaseNote;

    display: boolean = false;
    displayMsg: string = "";
    needToGoBack: boolean = false;

    FILEUPLOAD_URL: string = MAIN.URL.FILEUPLOAD_URL;

    releaseTypes: any = [
        {
            id: 0,
            label: 'Business App'
        },
        {
            id: 1,
            label: 'User App'
        },
        {
            id: 2,
            label: 'Web Order Page'
        }
    ];


    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '10cm',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter content here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' }
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: this.FILEUPLOAD_URL,
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ]
    };

    paramsSub: any;
    ngOnInit() {

        this.paramsSub = this.activatedRoute.queryParams.subscribe(queryParams => {

            if (queryParams['releaseType'] === undefined) {
                this.mode = "Register";

            } else {
                this.mode = "Modify";
                this.postService.getReleaseNote(queryParams['releaseType'], queryParams['version'])
                    .subscribe(data => {
                        this.releaseNote = data;
                    },
                        err => {
                            console.log(err);
                        });
            }
        });
    }

    ngOnDestroy() {
        this.paramsSub.unsubscribe();
    }


    constructor(public dialog: MatDialog, private activatedRoute: ActivatedRoute, public authService: AuthService, private router: Router, private route: ActivatedRoute, private postService: PostService) {
        this.releaseNote = new ReleaseNote();
        this.releaseNote.releaseType = 0;
        this.releaseNote.version = "";
        //this.releaseNote._id = "";
        this.releaseNote.title = "";
        this.releaseNote.detail = "";
        this.releaseNote.author = authService.currentUser?.name || 'Unknown';
    }

    register(): void {

        if (this.releaseNote._id === '' || this.releaseNote._id === undefined) {
            this.postService.addReleaseNote(this.releaseNote)
                .subscribe(res => {
                    if (res.error == ErrorCode.SUCCESS) {
                        console.log(this.route.snapshot);
                        let returnUrl = '/home/releasenote';
                        this.router.navigate([returnUrl]);
                    } else {
                        this.openDialog("Failed", res.message);
                    }
                },
                    err => {
                        console.log(err);
                    });
        } else {
            this.postService.updateReleaseNote(this.releaseNote._id, this.releaseNote)
                .subscribe(res => {
                    if (res.error == ErrorCode.SUCCESS) {
                        console.log(this.route.snapshot);
                        let returnUrl = '/home/releasenote';
                        this.router.navigate([returnUrl]);
                    } else {
                        this.openDialog("Failed", res.message);
                    }
                },
                    err => {
                        console.log(err);
                    });
        }
    }

    openDialog(title: string, content: string) {
        const dialogRef = this.dialog.open(ResultDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

}
