<div id="wrapper">
    <div>
        <as-navbar></as-navbar>
    </div>
  
    <div id="page-wrapper" class="gray-bg">
        <router-outlet></router-outlet>
    </div>

    <div>
        <as-footer></as-footer>
    </div>
</div>

  