<div role="main" class="main">

    <section class="page-header page-header-classic">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active">FAQ</li>
                    </ul>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col p-static">
                    <h1 data-title-border>FAQ</h1>

                </div>
            </div> -->
        </div>
    </section>

    <div class="container py-4">

        <div class="row">
            <div class="col">

                <h2 class="font-weight-normal text-7 mb-2">Frequently Asked <strong class="font-weight-extra-bold">Questions</strong></h2>
                
                <hr class="solid my-5">

                <div class="toggle toggle-primary" data-plugin-toggle="" id="myToggle">
                    <section class="toggle active">
                        <a class="toggle-title">How long does it take to set up?</a>
                        <div class="toggle-content">
                            <p>The typical setup is about 1 hr and will also depend on how many menu items you have in your restaurant.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">How much does the system cost?</a>
                        <div class="toggle-content">
                            <p>QonUS monthly subscription is $80 per month for Main Station, $40 per month for Staff Device & 5% of takeaway sales made via QonUS user app.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Can I cancel at any time?</a>
                        <div class="toggle-content">
                            <p>Cancellation can be done at any time as there is no lock-in contract.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">What are the system requirements?</a>
                        <div class="toggle-content">
                            <p>QonUS is designed to work on any tablet or mobile device.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Can I have more than 1 staff device?</a>
                        <div class="toggle-content">
                            <p>You can have as many staff device as required by making a deposit for the device and paying an additional subscription fee of $40 per month.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Can I upload my own menu photos?</a>
                        <div class="toggle-content">
                            <p>Yes. You can manage your menu by uploading menu photos as often as you like.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Can I get a consultant to help me setup?</a>
                        <div class="toggle-content">
                            <p>Our consultants are available to assist you in setting up.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Is there support for the software?</a>
                        <div class="toggle-content">
                            <p>Yes. Software support is available via our website or by calling 0493 528 338</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Can I use my own tablet?</a>
                        <div class="toggle-content">
                            <p>Yes. You can use your own tablet rather than one supplied by us. We will provide you with a link to download the software on to your tablet.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Is QonUS available Australia wide?</a>
                        <div class="toggle-content">
                            <p>QonUS is available Australia wide. Should you decide to use our device we will post you the devices with the software pre-installed.</p>
                        </div>
                    </section>

                    <section class="toggle">
                        <a class="toggle-title">Is QonUS available outside of Australia?</a>
                        <div class="toggle-content">
                            <p>QonUS is currently available only in Australia but will be available internationally shortly.</p>
                        </div>
                    </section>
                </div>

            </div>

        </div>

    </div>

</div>