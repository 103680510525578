import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-qonus-whayqonus',
    templateUrl: './qonus.whyqonus.component.html'
})
export class QonUSWhyQonUSComponent {
    headerLayout: string;

    constructor(private router:Router) {
    }
}
