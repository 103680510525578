import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { AuthService } from '../../shared/user/auth.service';
import { PostService } from '../../shared/services/post/post.service'
import { Post } from '../../shared/services/post/post';

import * as _ from 'lodash';
import { ErrorCode } from 'src/app/shared/constant/errorcode';

import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from 'src/app/shared/components/result-dialog/result-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'as-blog',
    templateUrl: './blog.component.html'
})
export class BlogComponent {

    dataLoading: boolean = true;
    searchWord: string = "";

    currentPageIndex: number = 0;
    pageSize: number = 5;

    posts: Post[] = [];

    options: any[] = [];
    loggedin: boolean = false;

    @ViewChildren('articlePosts') articlesView: QueryList<any> | undefined;

    constructor(public dialog: MatDialog, private postService: PostService, public authService: AuthService) {
        this.getData('');
        this.loggedin = authService.isLoggedIn();
    }

    ngAfterViewInit() {
        this.articlesView!.changes.subscribe(t => {
            for (var i = 0; i < this.posts.length; ++i) {
                var wrapper = document.getElementById('poster_' + i.toString());
                if (wrapper != null) {
                    wrapper.innerHTML = this.posts[i].detail;
                }
            }
        })
    }


    getData(searchWord: string): void {

        this.postService.getPosts(searchWord, this.currentPageIndex, this.pageSize)
            .subscribe(data => {
                this.posts = data;





                // if(this.posts.length > 0) {
                //     var wrapper= document.getElementById('poster_0');
                //     wrapper.innerHTML= this.posts[0].detail;
                // }
                // if(this.posts.length > 1) {
                //     var wrapper= document.getElementById('poster2');
                //     wrapper.innerHTML= this.posts[1].detail;
                // }
                // if(this.posts.length > 2) {
                //     var wrapper= document.getElementById('poster3');
                //     wrapper.innerHTML= this.posts[2].detail;
                // }



            },
                err => {
                    console.log(err);
                });
    }

    search(): void {
        this.getData(this.searchWord);
    }

    logout(): void {
        this.authService.logoutWithoutRedirect();
    }

    copyLink(): void {
        // this.clipboard.writeText('https://base.qonus.com.au/home/blog/' + id);
        // this.openDialog('Copy', "Link to this blog successfully copied to Clipboard");
    }

    openDialog(title: string, content: string) {
        return this.dialog.open(ResultDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

    openConfirmDialog(title: string, content: string): any {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

    delete(id: string, title: string): void {
        this.openConfirmDialog("Delete", "Do you want to delete '" + title + "'?").afterClosed().subscribe((result: boolean) => {
            if (result == true) {
                this.postService.deletePost(id)
                    .subscribe(res => {
                        if (res.error == ErrorCode.SUCCESS) {
                            this.openDialog("Success", "Post was deleted").afterClosed().subscribe(result => {
                                this.getData('');
                            });

                        } else {
                            this.openDialog("Failed", res.message);
                        }
                    },
                        err => {
                            console.log(err);
                        });
            }
        });
    }
}
