import { Component, ViewChild } from '@angular/core';

import { Router } from '@angular/router';

import { QonUSService } from '../../../shared/services/qonus/qonus.service';
import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../../../shared/components/result-dialog/result-dialog.component';

@Component({
  selector: 'app-contactus-sales',
  templateUrl: './contactus.sales.component.html',
  styleUrls: ['./contactus.sales.component.css']
})
export class ContactUSSalesComponent {

  @ViewChild('registerForm') registerForm: any;

  restaurantName: String = '';
  restaurantAddressStreet: String = '';
  restaurantAddressSuburb: String = '';
  restaurantAddressPostCode: String = '';

  firstName: String = '';
  lastName: String = '';
  phone: String = '';

  constructor(private router: Router, public dialog: MatDialog, private qonusService: QonUSService) {
  }

  openDialog(title: string, content: string) {
    const dialogRef = this.dialog.open(ResultDialogComponent, {
      data: {
        'title': title,
        'content': content,
      }
    });

  }

  salesContact(): void {

    var body = {
      restaurantName: this.restaurantName,
      restaurantAddressStreet: this.restaurantAddressStreet,
      restaurantAddressSuburb: this.restaurantAddressSuburb,
      restaurantAddressPostCode: this.restaurantAddressPostCode,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      email: "admin@qonus.com.au",
    };


    this.qonusService.salesContact(body)
      .subscribe(data => {
        if (data.error == 0) {
          this.openDialog("Success", "We will be contact you soon.\nThank you!");

        } else {
          this.openDialog("Error", "Failed to send to inquiry.\nCould you please call us");
        }

      },
        err => {
          console.log(err);
        });
  }

}
