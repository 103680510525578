import { Component, Input, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { PostService } from '../../../shared/services/post/post.service';
import { AuthService } from '../../../shared/user/auth.service';
import { Post } from '../../../shared/services/post/post';

import { MAIN } from '../../../shared/main';
import { ErrorCode } from '../../../shared/constant/errorcode';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../../../shared/components/result-dialog/result-dialog.component';

@Component({
    selector: 'as-blog-posting',
    templateUrl: './blog.posting.component.html'
})
export class BlogPostingComponent {

    @ViewChild('registerForm') registerForm: any;

    mode: string = "Register";
    post: Post;

    display: boolean = false;
    displayMsg: string = "";
    needToGoBack: boolean = false;

    FILEUPLOAD_URL: string = MAIN.URL.FILEUPLOAD_URL;

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '10cm',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter content here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'comic-sans-ms', name: 'Comic Sans MS' }
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: this.FILEUPLOAD_URL,
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ]
    };

    paramsSub: any;
    ngOnInit() {

        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.post._id = params['id'];

            if (this.post._id === undefined) {
                this.mode = "Register";

            } else {
                this.mode = "Modify";
                this.postService.getPost(this.post._id)
                    .subscribe(data => {
                        this.post = data;
                    },
                        err => {
                            console.log(err);
                        });
            }
        });
    }

    ngOnDestroy() {
        this.paramsSub.unsubscribe();
    }


    constructor(public dialog: MatDialog, private activatedRoute: ActivatedRoute, public authService: AuthService, private router: Router, private route: ActivatedRoute, private postService: PostService) {
        this.post = new Post();
        this.post._id = '';
        this.post.title = "";
        this.post.category = "";
        this.post.detail = "";
        this.post.author = authService.currentUser!.name || 'Unknown';
    }

    register(): void {

        if (this.post._id === undefined) {
            this.postService.addPost(this.post)
                .subscribe(res => {
                    if (res.error == ErrorCode.SUCCESS) {
                        console.log(this.route.snapshot);
                        let returnUrl = '/home/blog';
                        this.router.navigate([returnUrl]);
                    } else {
                        this.openDialog("Failed", res.message);
                    }
                },
                    err => {
                        console.log(err);
                    });
        } else {
            this.postService.updatePost(this.post._id, this.post)
                .subscribe(res => {
                    if (res.error == ErrorCode.SUCCESS) {
                        console.log(this.route.snapshot);
                        let returnUrl = '/home/blog';
                        this.router.navigate([returnUrl]);
                    } else {
                        this.openDialog("Failed", res.message);
                    }
                },
                    err => {
                        console.log(err);
                    });
        }
    }

    openDialog(title: string, content: string) {
        const dialogRef = this.dialog.open(ResultDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

}
