import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-feature-booking',
    templateUrl: './feature.booking.component.html'
})
export class FeatureBookingComponent {
    headerLayout: string;

    constructor(private router:Router) {
    }
}
