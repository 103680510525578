<div role="main" class="main">

    <div class="owl-carousel-wrapper" style="height: 500px;">
        <div class="owl-carousel dots-inside dots-horizontal-center show-dots-hover dots-light nav-style-1 nav-inside nav-inside-plus nav-dark nav-lg nav-font-size-lg show-nav-hover mb-0" data-plugin-options="{'responsive': {'0': {'items': 1}, '479': {'items': 1}, '768': {'items': 1}, '979': {'items': 1}, '1199': {'items': 1}}, 'loop': false, 'autoHeight': false, 'margin': 0, 'dots': true, 'dotsVerticalOffset': '-35px', 'nav': false, 'animateIn': 'fadeIn', 'animateOut': 'fadeOut', 'mouseDrag': false, 'touchDrag': false, 'pullDrag': false, 'autoplay': true, 'autoplayTimeout': 9000, 'autoplayHoverPause': true, 'rewind': true}">
            
            <!-- Carousel Slide 1 -->
            <div id="slide3" class="position-relative overlay overlay-show overlay-op-7" data-dynamic-height="['500px']" style="background-image: url(../assets/img/queuing_1000x450.png); background-size: cover; background-position: center; height: 500px;">
                <div class="container position-relative z-index-3 h-100">
                    <div class="row justify-content-center align-items-center h-100">
                        <div class="col-lg-7 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center h-100">

                                <h1 class="text-color-light font-weight-extra-bold text-12-13 line-height-3 mb-2 appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="500" data-plugin-options="{'minWindowWidth': 0}">Queuing</h1>
                                <p class="text-4-5 text-color-light font-weight-light opacity-7 text-center mb-5 px-lg-5" data-plugin-animated-letters data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationSpeed': 30}"><strong class="text-color-light">Make customers happy with virtual queuing.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </div>
    </div>

    <div class="container my-5 py-3" id="main">
        <div class="row ml-2">
            <h4 class="mb-2 mt-5 text-8 font-weight-bold text-primary">How it works</h4>
        </div>
        <div class="row">
            <div class="col">
    
                <div class="row process process-connecting-line my-5">
                    <div class="connecting-line appear-animation d-none d-lg-block" data-appear-animation="fadeIn"></div>
                    <div class="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600">
                        <div class="process-step-circle-icon mb-3">
                            <strong class="process-step-circle-content"><i class="fal fa-mobile fa-3x"></i></strong>
                        </div>
                        
                        <div class="process-step-circle">
                            <strong class="process-step-circle-content">1</strong>
                        </div>
                        <div class="process-step-content">
                            <h4 class="mb-1 text-4 font-weight-bold">Tap or Search</h4>
                            <p class="mb-0">The customer taps their device on QonUS queuing pad or uses the app if they are not on-site.</p>
                        </div>
                    </div>
                    <div class="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="800">
                        <div class="process-step-circle-icon mb-3">
                            <strong class="process-step-circle-content"><i class="fal fa-user-friends fa-3x"></i></strong>
                        </div>

                        <div class="process-step-circle">
                            <strong class="process-step-circle-content">2</strong>
                        </div>
                        <div class="process-step-content">
                            <h4 class="mb-1 text-4 font-weight-bold">Queue</h4>
                            <p class="mb-0">Enter details and join the queue.</p>
                        </div>
                    </div>
                    <div class="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1000">
                        <div class="process-step-circle-icon mb-3">
                            <strong class="process-step-circle-content"><i class="fal fa-bell fa-3x"></i></strong>
                        </div>

                        <div class="process-step-circle">
                            <strong class="process-step-circle-content">3</strong>
                        </div>
                        <div class="process-step-content">
                            <h4 class="mb-1 text-4 font-weight-bold">Notify</h4>
                            <p class="mb-0">The customer receives notifications of queue status.</p>
                        </div>
                    </div>
                    

                </div>
    
            </div>
        </div>
    </div>

    
    <!-- <div class="process-step col-lg-3 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1200">
        <div class="process-step-circle-icon mb-3">
            <strong class="process-step-circle-content"><i class="fal fa-concierge-bell fa-3x"></i></strong>
        </div>

        <div class="process-step-circle">
            <strong class="process-step-circle-content"><i class="fal fa-clock fa-1x"></i></strong>
        </div>
        <div class="process-step-content">
            <h4 class="mb-1 text-4 font-weight-bold">Staff assistance</h4>
            <p class="mb-0">The staff will be with you soon.</p>
        </div>
    </div> -->

    <section class="section section-primary section-no-border section-height-3 my-0 appear-animation"
			data-appear-animation="fadeIn">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 appear-animation" data-appear-animation="fadeInRightShorter"
						data-appear-animation-delay="300">

						<h2 class="font-weight-bold line-height-1 text-7 negative-ls-1 mb-3">The Best All-In-One system
							for Restaurants</h2>
						<p class="text-color-light opacity-8 text-4 mb-4">QonUS is an application integrating restaurant table management, ordering, POS, and queuing into a single platform. We've worked hard making things simple so you can focus on running your business. We've simplified how you and your customer interacts ensuring everyone has an exceptional experience.</p>

						<a href="/home/contactus/sales" class="btn btn-dark font-weight-semibold btn-px-5 btn-py-2 text-2 rounded-0">GET STARTED</a>
					</div>
				</div>
			</div>
		</section>


    <div class="container my-5 py-3" id="main">
        <div class="row ml-2">
            <h4 class="mb-2 mt-5 text-8 font-weight-bold text-primary">Features</h4>
        </div>

        <div class="row pt-4">
            <div class="col-lg-6 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">
                <div class="feature-box feature-box-style-2">
                    <div class="feature-box-icon">
                        <i class="icon-people icons"></i>
                    </div>
                    <div class="feature-box-info">
                        <h4 class="font-weight-bold mb-2">Virtual Queuing</h4>
                        <p class="mb-4">Your customers can join the queue without being on-site.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 appear-animation" data-appear-animation="fadeIn">
                <div class="feature-box feature-box-style-2">
                    <div class="feature-box-icon">
                        <i class="icon-bell icons"></i>
                    </div>
                    <div class="feature-box-info">
                        <h4 class="font-weight-bold mb-2">Notifications</h4>
                        <p class="mb-4">We'll keep your customers informed by updating them on where they are in the queue.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-lg-3">
            <div class="col-lg-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
                <div class="feature-box feature-box-style-2">
                    <div class="feature-box-icon">
                        <i class="icon-graph icons"></i>
                    </div>
                    <div class="feature-box-info">
                        <h4 class="font-weight-bold mb-2">Data</h4>
                        <p class="mb-4">We'll record metrics so you'll know how you are performing.</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    
    
</div>