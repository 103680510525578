declare const require: any;

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ScriptService } from './shared/utils/script.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router, private scriptService: ScriptService) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
      /** END */
    })
  }

  ngAfterViewInit() {
    this.scriptService.load('theme', 'themeinit').then(data => {
      console.log('script loaded', data);
    })
    //We loading the player script on after view is loaded
    // import('../js/theme.js');
    // import('../js/theme.init.js');
  }
}
