<div class="container py-4">
    <div class="row">
        <div class="col">
            <form #registerForm="ngForm" (ngSubmit)="register()" role="form">

                <div class="row mb-4">
                    <div class="col-12">
                        <fieldset class="form-fieldset">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Release Type</label>
                                <div class="col-lg-6">
                                    <select class="form-control" [(ngModel)]="releaseNote.releaseType"
                                        name="releaseType">
                                        <option *ngFor="let option of releaseTypes" [ngValue]="option.id">
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Version</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" placeholder="Enter version"
                                        [(ngModel)]="releaseNote.version" name="version">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Title</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" placeholder="Enter title"
                                        [(ngModel)]="releaseNote.title" name="title">
                                </div>
                            </div>

                            <!-- <div class="form-group row">
                                <label class="col-sm-auto col-form-label">Content</label>
                                <div class="col-sm-8">
                                    <textarea class="form-control" [(ngModel)]="post.detail"
                                        name="postDetail"></textarea>
                                </div>
                            </div> -->

                            <div style="height:400px;">
                                <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig"
                                    name="detail" [(ngModel)]="releaseNote.detail"></angular-editor>

                            </div>


                            <!-- <div class="form-group row">
                                <label class="col-sm-auto control-label">CreatedTime</label>
                                <div class="col-sm-3">
                                    <label class="control-label">{{post.createdTime | wtDateV2}}</label>
                                </div>
    
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-auto control-label">ModifiedTime</label>
                                <label class="col-sm-3 control-label">{{post.modifiedTime | wtDateV2}}</label>
                            </div> -->
                        </fieldset>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 justify-content-center mt-5">
                        <button type="submit" class="btn btn-primary mr-2"
                            [disabled]="!registerForm.form.valid">{{mode}}</button>
                        <!-- <a [routerLink]="['/home/product/list']">
                            <button type="button" class="btn btn-primary">Cancel</button>
                        </a> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>