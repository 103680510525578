import { Pipe, PipeTransform, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";

import { DATE_FORMAT } from "./date-format-token";

@Pipe({
  name: "wtDateV2"
})
export class WtDateV2Pipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    @Inject(DATE_FORMAT) private dateFormat: any
  ) { }

  transform(value: Date | number | string, ...options: string[]) {
    return this.datePipe.transform(value, this.dateFormat, ...options);
  }
}
