import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html'
})
export class PolicyComponent {
    headerLayout: string = '';

    constructor(private router: Router) {
    }
}
