<div role="main" class="main">

    <section class="page-header page-header-classic">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active">Pricing</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="container py-2">

        <div class="row mb-2">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <hr class="solid my-5">

                <div class="mb-5">
                    <h2 class="font-weight-bold"><strong>QonUS</strong> PRICING</h2>
                </div>

                <div>
                    <h4>MONTHLY SUBSCRIPTION FEES</h4>
                    <h6>QonUS has two pricing components (Main Station & Staff Device)</h6>
                </div>



            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="pricing-table pricing-table-no-gap mb-5">
            <div class="col-md-2"></div>

            <div class="col-md-4">
                <div class="plan">
                    <div class="plan-header bg-primary">
                        <h3>Main Station</h3>
                    </div>
                    <div class="plan-price">
                        <!-- <span class="promotion">Promotional Price</span> -->
                        <!-- <span class="promotion2">* LIMIED TIME ONLY</span> -->
                        <span class="price"><span class="price-unit">$</span>
                            120
                            <!-- <i class="ml-3 mr-3 fas fa-caret-right"></i><span class="price-unit">$</span>80 -->
                        </span>
                        <label class="price-label">PER MONTH</label>
                    </div>

                    <div class="plan-features" style="height:400px;">
                        <h4 class="text-color-primary">FEATURES</h4>

                        <div class="feature-box feature-box-style-2 mb-3" style="text-align: start;">
                            <div class="feature-box-icon">
                                <i class="fal fa-utensils fa-2x"></i>
                            </div>
                            <div class="feature-box-info">
                                <h5 class="font-weight-bold mb-2">Table Management</h5>
                                <h6 class="mb-1 text-2">- Set number of tables</h6>
                                <h6 class="mb-1 text-2">- Manage table session</h6>
                                <h6 class="mb-1 text-2">- Customer assistance notification</h6>
                            </div>
                        </div>

                        <div class="feature-box feature-box-style-2 mb-3" style="text-align: start;">
                            <div class="feature-box-icon">
                                <i class="fal fa-hamburger fa-2x"></i>
                            </div>
                            <div class="feature-box-info">
                                <h5 class="font-weight-bold mb-2">Ordering</h5>
                                <h6 class="mb-1 text-2">- Dine-in / takeaway ordering</h6>
                                <h6 class="mb-1 text-2">- Confirm or Reject the order</h6>
                                <h6 class="mb-1 text-2">- Print order dockets for the kitchen</h6>
                            </div>
                        </div>

                        <div class="feature-box feature-box-style-2" style="text-align: start;">
                            <div class="feature-box-icon">
                                <i class="icon-credit-card icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h5 class="font-weight-bold mb-2">Payment</h5>
                                <h6 class="mb-1 text-2">- Accept payment by card or cash</h6>
                                <h6 class="mb-1 text-2">- Split bills</h6>
                                <h6 class="mb-1 text-2">- Print receipts and track sales</h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="plan plan-header">
                    <div class="plan-header bg-secondary">
                        <h3>Staff device</h3>
                    </div>
                    <div class="plan-price">
                        <!-- <span class="promotion">Promotional Price</span> -->
                        <!-- <span class="promotion2">* LIMIED TIME ONLY</span> -->
                        <span class="price"><span class="price-unit">$</span>
                            <!-- <span style="text-decoration: line-through; color:grey; font-size: smaller;">60</span> -->
                            40
                            <!-- <i class="ml-3 mr-3 fas fa-caret-right"></i><span class="price-unit">$</span>40-->
                        </span>
                        <label class="price-label">PER MONTH</label>
                    </div>

                    <div class="plan-features" style="height:400px;">
                        <h4 class="text-color-primary">FEATURES</h4>

                        <div class="feature-box feature-box-style-2 mb-3" style="text-align: start;">
                            <div class="feature-box-icon">
                                <i class="fal fa-utensils fa-2x"></i>
                            </div>
                            <div class="feature-box-info">
                                <h5 class="font-weight-bold mb-2">Table Management</h5>
                                <h6 class="mb-1 text-2">- Manage table session</h6>
                                <h6 class="mb-1 text-2">- Customer assistance notification</h6>
                            </div>
                        </div>

                        <div class="feature-box feature-box-style-2 mb-3" style="text-align: start;">
                            <div class="feature-box-icon">
                                <i class="fal fa-hamburger fa-2x"></i>
                            </div>
                            <div class="feature-box-info">
                                <h5 class="font-weight-bold mb-2">Ordering</h5>
                                <h6 class="mb-1 text-2">- Dine-in / takeaway ordering</h6>
                            </div>
                        </div>

                        <div class="feature-box feature-box-style-2 mb-3" style="text-align: start;">
                            <div class="feature-box-icon">
                                <!-- <i class="icon-people icons"></i> -->
                                <i class="fal fa-user-friends fa-2x"></i>
                            </div>
                            <div class="feature-box-info">
                                <h5 class="font-weight-bold mb-2">Queuing</h5>
                                <h6 class="mb-1 text-2">- Manage restaurant queue</h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-2"></div>

        </div>

        <div class="row mb-5 mt-5">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div>
                    <h4>TAKEAWAY TRANSACTION FEES</h4>
                    <h6>Only 3% for the takeaway orders from QonUS Mobile App</h6>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row mb-5 mt-5">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div>
                    <h4>SERVICES</h4>
                    <h6>Initial Setup</h6>
                    <ul>
                        <li>Setting Main station and Staff device (connecting docket printer)</li>
                        <li>Staff training</li>
                    </ul>
                    <h6>Ongoing software update</h6>
                    <h6>Rapid response support</h6>
                    <h6>Dedicated account manager</h6>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row">
            <div class="col">
            </div>
            <div class="col" style="text-align: center;">
                <a href="/home/contactus/sales" class="btn btn-primary btn-modern py-2 px-4">GET STARTED</a>
            </div>
            <div class="col">
            </div>

        </div>

    </div>

</div>