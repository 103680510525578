import { Component, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';

import { AuthService } from '../../shared/user/auth.service';
import { PostService } from '../../shared/services/post/post.service'
import { Post } from '../../shared/services/post/post';

import { Observable } from 'rxjs/Rx';
import * as _ from 'lodash';
import { ErrorCode } from 'src/app/shared/constant/errorcode';

import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../../shared/components/result-dialog/result-dialog.component';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { ReleaseNote } from 'src/app/shared/services/post/releasenote';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'as-release',
    templateUrl: './release.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ReleaseComponent {

    dataLoading: boolean = true;
    searchWord: string = "";

    currentPageIndex: number = 0;
    pageSize: number = 5;

    releaseNotes: ReleaseNote[] = [];
    pages: any[] = [];
    totalCount: number = 0;

    options: any[] = [];
    loggedin: boolean = false;

    directAccess: boolean = false;

    activeLink = '0';

    @ViewChildren('articlePosts') articlesView: QueryList<any> | undefined;

    constructor(public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private postService: PostService, public authService: AuthService) {

        this.loggedin = authService.isLoggedIn();
    }

    paramsSub: any;
    ngOnInit() {
        console.log(this.router.url);

        if (this.router.url.startsWith('/releasenote') == true) {
            // from host app
            this.directAccess = true;
        }

        this.paramsSub = this.activatedRoute.queryParams.subscribe(queryParams => {

            if (queryParams['releaseType'] === undefined) {
                this.getData(0);

            } else {
                this.activeLink = queryParams['releaseType'];
                this.postService.getReleaseNote(queryParams['releaseType'], queryParams['version'])
                    .subscribe(data => {
                        this.releaseNotes.push(data);
                        this.articlesView?.changes.subscribe(t => {
                            for (var i = 0; i < this.releaseNotes.length; ++i) {
                                var wrapper = document.getElementById('releasenote_' + i.toString());
                                if (wrapper != null) {
                                    wrapper.innerHTML = this.releaseNotes[i].detail;
                                }
                            }
                        })
                    },
                        err => {
                            console.log(err);
                        });
            }
        });
    }

    ngOnDestroy() {
        this.paramsSub.unsubscribe();
    }

    select(tab: string) {
        this.activeLink = tab;
        this.currentPageIndex = 0;

        this.getData(parseInt(tab));
    }


    getData(releaseType: number): void {

        this.postService.getReleaseNotes(releaseType, this.currentPageIndex * this.pageSize, this.pageSize)
            .subscribe(data => {
                this.releaseNotes = data.releaseNotes;
                this.totalCount = data.totalCount;

                this.pages = [];
                for (var i = 0, j = 0; i < this.totalCount; i += this.pageSize, j++) {
                    this.pages.push({ pageIndex: j });
                }

                if (this.currentPageIndex > this.pages.length - 1)
                    this.currentPageIndex = Math.max(0, this.pages.length - 1);

            },
                err => {
                    console.log(err);
                });
    }

    pageChange(pageIndex: number): void {
        if (this.currentPageIndex == pageIndex || pageIndex < 0 || pageIndex > this.pages.length - 1)
            return;

        this.currentPageIndex = pageIndex;
        this.getData(parseInt(this.activeLink));

        window.scrollTo(0, 0);
    }

    logout(): void {
        this.authService.logoutWithoutRedirect();
    }

    copyLink(): void {
        // this.clipboard.writeText('https://base.qonus.com.au/home/blog/' + id);
        // this.openDialog('Copy', "Link to this blog successfully copied to Clipboard");
    }

    openDialog(title: string, content: string) {
        return this.dialog.open(ResultDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

    openConfirmDialog(title: string, content: string): any {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                'title': title,
                'content': content,
            }
        });
    }

    delete(id: string, title: string): void {
        this.openConfirmDialog("Delete", "Do you want to delete '" + title + "'?").afterClosed().subscribe((result: boolean) => {
            if (result == true) {
                this.postService.deleteReleaseNote(id)
                    .subscribe(res => {
                        if (res.error == ErrorCode.SUCCESS) {
                            this.openDialog("Success", "ReleaseNote was deleted").afterClosed().subscribe(result => {
                                this.getData(parseInt(this.activeLink));
                            });

                        } else {
                            this.openDialog("Failed", res.message);
                        }
                    },
                        err => {
                            console.log(err);
                        });
            }
        });
    }
}
