import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { QonUSService } from './shared/services/qonus/qonus.service';
import { PostService } from './shared/services/post/post.service';
import { AuthService } from './shared/user/auth.service';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';

import { ResultDialogComponent } from './shared/components/result-dialog/result-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeComponent } from './home/home.component';
import { MainComponent } from './home/main/main.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';

import { ContactUSFaqComponent } from './home/contactus/faq/contactus.faq.component';
import { ContactUSSalesComponent } from './home/contactus/sales/contactus.sales.component';
import { ContactUSSupportComponent } from './home/contactus/support/contactus.support.component';

import { FeatureQueuingComponent } from './home/feature/queuing/feature.queuing.component';
import { FeatureBookingComponent } from './home/feature/booking/feature.booking.component';
import { FeatureDineInComponent } from './home/feature/dinein/feature.dinein.component';
import { FeatureTakeawayComponent } from './home/feature/takeaway/feature.takeaway.component';

import { QonUSWhatItIsComponent } from './home/qonus/whatitis/qonus.whatitis.component';
import { QonUSComparisonComponent } from './home/qonus/comparison/qonus.comparison.component';
import { PricingComponent } from './home/pricing/pricing.component';

import { BlogComponent } from './home/blog/blog.component';
import { BlogPostingComponent } from './home/blog/posting/blog.posting.component';

import { ReleaseComponent } from './home/release/release.component';
import { ReleasePostingComponent } from './home/release/posting/release.posting.component';

import { LoginComponent } from './account/login/login.component';

import { WtDateV2Pipe } from './shared/utils/wt-date-v2.pipe';
import { DatePipe } from '@angular/common';
import { DATE_FORMAT } from './shared/utils/date-format-token';
import { ENV } from './shared/main';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { AuthGuardAdmin, AuthGuardManager } from './shared/user/authguard-admin';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ScriptService } from './shared/utils/script.service';
import { PolicyComponent } from './home/policy/policy.component';

@NgModule({
  declarations: [
    AppComponent,
    ResultDialogComponent,
    ConfirmDialogComponent,
    HomeComponent,
    MainComponent,
    ContactUSFaqComponent,
    ContactUSSalesComponent,
    ContactUSSupportComponent,
    NavbarComponent,
    FooterComponent,
    FeatureQueuingComponent,
    FeatureBookingComponent,
    FeatureDineInComponent,
    FeatureTakeawayComponent,
    QonUSWhatItIsComponent,
    QonUSComparisonComponent,
    PolicyComponent,
    PricingComponent,
    BlogComponent,
    BlogPostingComponent,
    ReleaseComponent,
    ReleasePostingComponent,
    LoginComponent,
    WtDateV2Pipe,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
  ],
  providers: [
    QonUSService,
    PostService,
    AuthService,
    ScriptService,
    AuthGuardAdmin,
    AuthGuardManager,
    DatePipe,
    { provide: DATE_FORMAT, useValue: ENV.DATETIME.FORMAT },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
