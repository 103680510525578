import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeatureQueuingComponent } from './home/feature/queuing/feature.queuing.component';
import { FeatureBookingComponent } from './home/feature/booking/feature.booking.component';
import { FeatureDineInComponent } from './home/feature/dinein/feature.dinein.component';
import { FeatureTakeawayComponent } from './home/feature/takeaway/feature.takeaway.component';

import { ContactUSFaqComponent } from './home/contactus/faq/contactus.faq.component';
import { ContactUSSalesComponent } from './home/contactus/sales/contactus.sales.component';
import { ContactUSSupportComponent } from './home/contactus/support/contactus.support.component';

import { HomeComponent } from './home/home.component';
import { MainComponent } from './home/main/main.component';

import { BlogComponent } from './home/blog/blog.component';

import { PricingComponent } from './home/pricing/pricing.component';
import { QonUSWhatItIsComponent } from './home/qonus/whatitis/qonus.whatitis.component';
import { QonUSWhyQonUSComponent } from './home/qonus/whyqonus/qonus.whyqonus.component';
import { QonUSComparisonComponent } from './home/qonus/comparison/qonus.comparison.component';
import { BlogPostingComponent } from './home/blog/posting/blog.posting.component';

import { LoginComponent } from './account/login/login.component';
import { AuthGuardManager } from './shared/user/authguard-admin';
import { ReleaseComponent } from './home/release/release.component';
import { ReleasePostingComponent } from './home/release/posting/release.posting.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'releasenote',
    component: ReleaseComponent,
  },
  {
    path: 'releasenote/:id',
    component: ReleaseComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      },
      {
        path: 'main',
        component: MainComponent,
      },
      {
        path: 'qonus',
        children: [
          { path: '', redirectTo: 'whatitis', pathMatch: 'full' },
          { path: 'whatitis', component: QonUSWhatItIsComponent },
          { path: 'whyqonus', component: QonUSWhyQonUSComponent },
          { path: 'comparison', component: QonUSComparisonComponent },
        ]
      },
      {
        path: 'feature',
        children: [
          { path: '', redirectTo: 'queuing', pathMatch: 'full' },
          { path: 'queuing', component: FeatureQueuingComponent },
          { path: 'booking', component: FeatureBookingComponent },
          { path: 'dinein', component: FeatureDineInComponent },
          { path: 'takeaway', component: FeatureTakeawayComponent },
        ]
      },
      {
        path: 'blog',
        component: BlogComponent,
      },
      {
        path: 'blogposting',
        component: BlogPostingComponent,
        canActivate: [AuthGuardManager],
      },
      {
        path: 'blogposting/:id',
        component: BlogPostingComponent,
        canActivate: [AuthGuardManager],
      },
      {
        path: 'releasenote',
        component: ReleaseComponent,
      },
      {
        path: 'releasenote/:id',
        component: ReleaseComponent,
      },
      {
        path: 'releasenoteposting',
        component: ReleasePostingComponent,
        canActivate: [AuthGuardManager],
      },
      {
        path: 'releasenoteposting/:id',
        component: ReleasePostingComponent,
        canActivate: [AuthGuardManager],
      },

      {
        path: 'contactus',
        children: [
          { path: '', redirectTo: 'sales', pathMatch: 'full' },
          { path: 'sales', component: ContactUSSalesComponent },
          { path: 'support', component: ContactUSSupportComponent },
          { path: 'faq', component: ContactUSFaqComponent },

        ]
      },
      {
        path: 'pricing',
        component: PricingComponent,
      },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
