<div role="main" class="main">
    
    <section class="page-header page-header-classic">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active">Why QonUS</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-no-border section-angled section-dark pb-0 m-0" style="background-repeat: no-repeat; background-color: #0169fe !important;" data-plugin-lazyload data-plugin-options="{'threshold': 500}" data-original="img/landing/reason_bg.png">
        <div class="section-angled-layer-top section-angled-layer-increase-angle bg-color-light-scale-1" style="padding: 4rem 0;"></div>
        <div class="spacer py-md-4 my-md-5"></div>
        <div class="container pt-5 mt-5">
            <div class="row align-items-center pt-md-5 mt-5">
                <div class="col-lg-6 offset-lg-1 mb-5">
                    <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">
                        <img src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/porto_dots.png" width="154" height="146" class="position-absolute top-auto" alt="6 reasons" data-plugin-float-element data-plugin-options="{'startPos': 'none', 'speed': 3, 'transition': true}" style="bottom: 184px; left: 70px;">
                    </div>
                    <div class="text-reasons">
                        <label class="text-color-light appear-animation z-index-1" data-appear-animation="blurIn" data-appear-animation-delay="250" data-appear-animation-duration="750">6</label>
                        <h3 class="text-4 text-md-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="450" data-appear-animation-duration="750">Reasons</h3>
                        <h3 class="text-4 text-md-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="750" data-appear-animation-duration="750">Why you should choose</h3>
                        <h3 class="text-4 text-md-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="950" data-appear-animation-duration="750"><span class="highlight-word light">Porto</span></h3>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-4 pl-lg-4 pl-xl-5 mb-5">
                    <h2 class="text-6 font-weight-semibold line-height-2 mb-2 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1150" data-appear-animation-duration="750">With Porto your satisfaction is guaranteed.</h2>
                    <p class="custom-text-color-1 line-height-5 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1350" data-appear-animation-duration="750">We have selected the 6 top reasons to choose Porto. Check below:</p>
                </div>
            </div>
            <div class="row justify-content-center mt-md-5 mb-4 pt-lg-4">
                <div class="col-lg-11">	
                    <div class="row justify-content-center">
                        <div class="col-10 col-sm-6 col-lg-4 image-box appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" data-appear-animation-duration="750">
                            <img class="img-fluid" alt="Speed Performance" src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/reason1.png">
                            <div class="d-flex align-items-center mb-2">
                                <span class="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">1</span>
                                <h4 class="d-flex flex-column font-weight-bold text-5 mb-0"><small class="font-weight-semibold positive-ls-2 line-height-1">OPTIMIZED FOR</small>Speed Performance</h4>
                            </div>
                            <p class="pr-5 custom-text-color-1">Choose Porto and make your site at high performance. We know your website must the fast as possible.</p>
                        </div>
                        <div class="col-10 col-sm-6 col-lg-4 image-box appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">
                            <img class="img-fluid" alt="Speed Performance" src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/reason2.png">
                            <div class="d-flex align-items-center mb-2">
                                <span class="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">2</span>
                                <h4 class="d-flex flex-column font-weight-bold text-5 mb-0"><small class="font-weight-semibold positive-ls-2 line-height-1">THE MOST</small>Solid and Tested Base</h4>
                            </div>
                            <p class="pr-5 custom-text-color-1">Porto has a very solid development base that has been improved since 2013 by our best developers.</p>
                        </div>
                        <div class="col-10 col-sm-6 col-lg-4 image-box appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000" data-appear-animation-duration="750">
                            <img class="img-fluid" alt="Speed Performance" src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/reason3.png">
                            <div class="d-flex align-items-center mb-2">
                                <span class="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">3</span>
                                <h4 class="d-flex flex-column font-weight-bold text-5 mb-0"><small class="font-weight-semibold positive-ls-2 line-height-1">TOP DESIGNS CREATED BY</small>Usability Specialists</h4>
                            </div>
                            <p class="pr-5 custom-text-color-1">The designs of all pages and all demos included in Porto were created by usability experts and modern designs standards.</p>
                        </div>
                        <div class="col-10 col-sm-6 col-lg-4 image-box appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300" data-appear-animation-duration="750">
                            <img class="img-fluid" alt="Speed Performance" src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/reason4.png">
                            <div class="d-flex align-items-center mb-2">
                                <span class="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">4</span>
                                <h4 class="d-flex flex-column font-weight-bold text-5 mb-0"><small class="font-weight-semibold positive-ls-2 line-height-1">THE BEST</small>Customer Experience</h4>
                            </div>
                            <p class="pr-5 custom-text-color-1">4.85 average rating based on 2.5k+ review proves that Porto is loved by our customers.</p>
                        </div>
                        <div class="col-10 col-sm-6 col-lg-4 image-box appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750">
                            <img class="img-fluid" alt="Speed Performance" src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/reason5.png">
                            <div class="d-flex align-items-center mb-2">
                                <span class="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">5</span>
                                <h4 class="d-flex flex-column font-weight-bold text-5 mb-0"><small class="font-weight-semibold positive-ls-2 line-height-1">WORKS PERFECTLY WITH</small>Porto Admin Template</h4>
                            </div>
                            <p class="pr-5 custom-text-color-1">With Porto Admin you can create your back-end panel with same design as the front-end site. Learn more in the next section.</p>
                        </div>
                        <div class="col-10 col-sm-6 col-lg-4 image-box appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="700" data-appear-animation-duration="750">
                            <img class="img-fluid" alt="Speed Performance" src="img/lazy.png" data-plugin-lazyload data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}" data-original="img/landing/reason6.png">
                            <div class="d-flex align-items-center mb-2">
                                <span class="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">6</span>
                                <h4 class="d-flex flex-column font-weight-bold text-5 mb-0"><small class="font-weight-semibold positive-ls-2 line-height-1">ALWAYS KEEP</small>Template Updates</h4>
                            </div>
                            <p class="pr-5 custom-text-color-1">Lifetime regular update makes porto always "best" compared to other competitors.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <a href="https://themeforest.net/item/porto-responsive-html5-template/4106987" class="btn btn-dark btn-rounded btn-modern btn-px-5 text-3 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="300" target="_blank">BUY PORTO NOW!</a>
            </div>
        </div>
    </section>


</div>