<div role="main" class="main">

    <section class="page-header page-header-classic">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active">Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <div class="container py-4">

        <div class="row">
            <div class="col">

                <h2 class="font-weight-normal text-7 mb-2">Need <strong class="font-weight-extra-bold">Help?</strong></h2>
                <div class="overflow-hidden">
                    <p class="mb-0">We will be with you soon.</p>
                </div>
                
                <hr class="solid my-4">

                <form role="form" class="needs-validation">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="container pb-5 mb-5">
                                <div class="row justify-content-center">
                                    <!-- <div class="col">
                                        <h2 class="font-weight-normal text-color-dark text-center text-8 mb-4"><strong
                                                class="font-weight-extra-bold">Contact</strong> Us</h2>
                                        <div class="text-4 opacity-8 text-center">Please leave us a message about anything you want to know.
                                        </div>
                                        <div class="text-4 opacity-8 text-center mt-0 mb-4">We'll be with you soon.</div>
                                        <form class="contact-form form-style-3" #registerForm="ngForm" (ngSubmit)="inquiry()" role="form">
                                            <div class="contact-form-success alert alert-success d-none">
                                                Message has been sent to us.
                                            </div>
                        
                                            <div class="contact-form-error alert alert-danger d-none">
                                                Error sending your message.
                                                <span class="mail-error-message text-1 d-block"></span>
                                            </div>
                        
                                            <input type="hidden" value="Contact Form" name="subject" id="subject">
                                            <div class="form-row">
                                                <div class="form-group col-md-6 pr-md-2">
                                                    <input type="text" value="" data-msg-required="Please enter your name." maxlength="100"
                                                        class="form-control h-auto py-2" placeholder="Your Name..." name="name" id="name" [(ngModel)]="name"
                                                        required>
                                                </div>
                                                <div class="form-group col-md-6 pl-md-2">
                                                    <input type="text" value="" data-msg-required="Please enter your phone." maxlength="100"
                                                        class="form-control h-auto py-2" placeholder="Your Phone..." name="phone" id="phone"
                                                        [(ngModel)]="phone" required>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="email" value="" data-msg-required="Please enter your email address."
                                                    data-msg-email="Please enter a valid email address." maxlength="100" class="form-control h-auto py-2"
                                                    placeholder="Your Email Address..." name="email" id="email" [(ngModel)]="email">
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col">
                                                    <textarea maxlength="5000" data-msg-required="Please enter your message." rows="4" class="form-control"
                                                        placeholder="Your Message..." name="message" id="message" [(ngModel)]="message"></textarea>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col text-center">
                                                    <input type="submit" value="SUBMIT" class="btn btn-primary font-weight-semibold text-3 px-5 btn-py-2"
                                                        data-loading-text="Loading...">
                                                </div>
                                            </div>
                                        </form>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <img class="img-fluid scale-1 pl-5 pr-5 my-4" src="../assets/img/support.jpeg"
						alt="layout styles" />
                            </div>
                            
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>