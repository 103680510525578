export class Post {
    _id: string;
    
    title: string;
    category: string;
    detail: string;
    author: string;
    
    createdTime: Date;
    modifiedTime: Date;
}
