import { Component, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core';

import { Router } from '@angular/router';

import { QonUSService } from '../../../shared/services/qonus/qonus.service';
import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../../../shared/components/result-dialog/result-dialog.component';

@Component({
  selector: 'as-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  @Input() brand: string = '';

  @ViewChild('registerForm') registerForm: any;

  name: String = '';
  phone: String = '';
  email: String = '';
  message: String = '';

  constructor(private router: Router, public dialog: MatDialog, private qonusService: QonUSService) {

  }


  openDialog(title: string, content: string) {
    const dialogRef = this.dialog.open(ResultDialogComponent, {
      data: {
        'title': title,
        'content': content,
      }
    });

  }

  inquiry(): void {

    var body = {
      name: this.name,
      phone: this.phone,
      email: this.email,
      message: this.message,
    };


    this.qonusService.inquiry(body)
      .subscribe(data => {
        if (data.error == 0) {
          this.openDialog("Success", "We will be contact you soon.\nThank you!");

        } else {
          this.openDialog("Error", "Failed to send to inquiry.\nCould you please call us");
        }

      },
        err => {
          console.log(err);
        });
  }
}
