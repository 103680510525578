<div class="body">


	<div role="main" class="main">

		<!-- <section class="page-header page-header-modern bg-color-light-scale-1 page-header-lg">
					<div class="container">
						<div class="row">

							<div class="col-md-12 align-self-center p-static order-2 text-center">


								<h1 class="font-weight-bold text-dark">Login</h1>

							</div>

							<div class="col-md-12 align-self-center order-1">


								<ul class="breadcrumb d-block text-center">
									<li><a href="#">Home</a></li>
									<li class="active">Pages</li>
								</ul>
							</div>
						</div>
					</div>
				</section> -->

		<div class="container py-4">

			<div class="row justify-content-center">
				<div class="col-md-6 col-lg-5 mb-5 mb-lg-0">
					<h2 class="font-weight-bold text-5 mb-0">Login</h2>
					<form *ngIf="loginForm" [formGroup]="loginForm" role="form">
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Email address <span
										class="text-color-danger">*</span></label>
								<input type="text" (ngModelChange)="emailInvalid = false" class="form-control"
									[formControl]="$any(loginForm).controls['email']" placeholder="Email" />
								<div *ngIf="$any(loginForm).controls['email']!.hasError('required') && $any(loginForm).controls['email'].touched || emailInvalid"
									class="alert alert-danger">
									{{msgEmail}}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Password <span
										class="text-color-danger">*</span></label>
								<input type="password" (ngModelChange)="passwordInvalid = false" class="form-control"
									[formControl]="$any(loginForm).controls['password']" placeholder="****" />
								<div *ngIf="$any(loginForm).controls['password'].hasError('required') && $any(loginForm).controls['password'].touched || passwordInvalid"
									class="alert alert-danger">
									{{msgPassword}}
								</div>
							</div>
						</div>
						<div class="form-row justify-content-between">
							<div class="form-group col-md-auto">
								<div class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" id="rememberme">
									<label class="custom-control-label cur-pointer text-2" for="rememberme">Remember
										Me</label>
								</div>
							</div>
							<div class="form-group col-md-auto">
								<a class="text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-2"
									href="#">Forgot Password?</a>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<button type="button" (click)="login(loginForm.value)"
									class="btn btn-dark btn-modern btn-block text-uppercase rounded-0 font-weight-bold text-3 py-3"
									data-loading-text="Loading...">Login</button>
							</div>
						</div>
					</form>
				</div>

				<div class="col-md-6 col-lg-5">
					<h2 class="font-weight-bold text-5 mb-0">Register</h2>
					<form *ngIf="registerForm" [formGroup]="registerForm" role="form">
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Email address <span
										class="text-color-danger">*</span></label>
								<input type="text" (ngModelChange)="emailInvalid = false" class="form-control"
									[formControl]="$any(registerForm).controls['email']" placeholder="Email" />
								<div *ngIf="$any(registerForm).controls['email'].hasError('required') && $any(registerForm).controls['email'].touched || emailInvalid"
									class="alert alert-danger">
									{{msgEmail}}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Password <span
										class="text-color-danger">*</span></label>
								<input type="password" (ngModelChange)="passwordInvalid = false" class="form-control"
									[formControl]="$any(registerForm).controls['password']" placeholder="****" />
								<div *ngIf="$any(registerForm).controls['password'].hasError('required') && $any(registerForm).controls['password'].touched || passwordInvalid"
									class="alert alert-danger">
									{{msgPassword}}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Name <span
										class="text-color-danger">*</span></label>
								<input type="text" class="form-control"
									[formControl]="$any(registerForm).controls['name']" placeholder="Name" />
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Job Title <span
										class="text-color-danger">*</span></label>
								<input type="text" class="form-control"
									[formControl]="$any(registerForm).controls['jobTitle']" placeholder="Job Title" />
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<label class="text-color-dark text-3">Code <span
										class="text-color-danger">*</span></label>
								<input type="password" (ngModelChange)="passwordInvalid = false" class="form-control"
									[formControl]="$any(registerForm).controls['code']" placeholder="****" />
								<div *ngIf="registerForm.get('code')?.hasError('required') && registerForm.get('code')?.touched"
									class="alert alert-danger">
									{{msgCode}}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<p class="text-2 mb-2">Your personal data will be used to support your experience
									throughout this website, to manage access to your account, and for other purposes
									described in our <a href="#" class="text-decoration-none">privacy policy.</a></p>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col">
								<button type="button" (click)="register(registerForm.value)"
									class="btn btn-dark btn-modern btn-block text-uppercase rounded-0 font-weight-bold text-3 py-3"
									data-loading-text="Loading...">Register</button>
							</div>
						</div>
					</form>
				</div>

			</div>

		</div>

	</div>


</div>