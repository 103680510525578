
<section class="page-header page-header-classic">
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li class="active">BLOG</li>
                </ul>
            </div>
            <div class="col-auto float-right">
                <a href="/home/blogposting">Write article</a>
                
                <a *ngIf="loggedin" href="" (click)="logout()" class="ml-3 text-white">Logout</a>
                
            </div>
        </div>
        <!-- <div class="row">
            <div class="col p-static">
                <h1 data-title-border>FAQ</h1>

            </div>
        </div> -->
    </div>
</section>
    
<div class="container py-4">
    <div class="row">
        <div class="col">
            <div class="blog-posts single-post">

                <div *ngIf="posts != null && posts.length == 0" class="row">
                    <div class="col"></div>
                    <div class="col-auto"><span style="font-size: 30px;">No Post</span></div>
                    <div class="col"></div>
                    
                </div>

                <article #articlePosts *ngFor="let post of posts; let i= index" class="mb-5 post post-large blog-single-post border-0 m-0 p-0">
                
                    <!-- <div class="post-image ml-0">
                        <a href="blog-post.html">
                            <img src="img/blog/wide/blog-11.jpg" class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt="" />
                        </a>
                    </div> -->

                    <div class="post-date ml-0">
                        <span class="day">{{post.createdTime | date:'d'}}</span>
                        <span class="month">{{post.createdTime | date:'LLL'}}</span>
                    </div>

                    <div class="post-content ml-0">
                        <h2 class="font-weight-semi-bold"><a href="blog-post.html">{{post.title}}</a></h2>

                        <div class="post-meta">
                            <span><i class="far fa-clock"></i> By {{post.createdTime | wtDateV2}}</span>
                            <span><i *ngIf="post.category != ''" class="far fa-folder ml-1"></i> {{post.category}} </span>
                            <!-- <span><i class="far fa-comments ml-1"></i> <a href="javascript:void(0)">12 Comments</a></span> -->

                            <span><a href="javascript:void(0)" (click)="copyLink()"><i class="fal fa-clipboard ml-2"></i> Copy link</a></span>

                        </div>

                        <div id="{{'poster_' + i}}"></div>

                        <div class="post-block mt-4 pt-2 post-author">
                            <h4 class="mb-1">Author</h4>
                            <div *ngIf="loggedin" class="mb-1">
                                <span><a [routerLink]="['/home/blogposting', post._id]"><i class="far fa-edit fa-lg"></i>&nbsp;Edit </a></span>
                                <span><a href="javascript:void(0)" (click)="delete(post._id, post.title)"><i class="far fa-trash-alt fa-lg ml-3"></i>&nbsp;Delete</a></span>
                            </div>
                            

                            <div class="img-thumbnail img-thumbnail-no-borders d-block pb-3">
                                <a href="blog-post.html">
                                    <img src="../assets/img/avatars/avatar.jpg" alt="">
                                </a>
                            </div>
                            <p><strong class="name"><a href="#" class="text-4 pb-2 pt-2 d-block">{{post.author}}</a></strong></p>
                            <p>QonUS Tech Lead</p>
                        </div>

                        <div class="post-block mt-5 post-leave-comment">
                            <h4 class="mb-3">Leave a comment</h4>

                            <form class="contact-form p-4 rounded bg-color-grey" action="php/contact-form.php" method="POST">			
                                <div class="p-2">
                                    <div class="form-row">
                                        <div class="form-group col-lg-6">
                                            <label class="required font-weight-bold text-dark">Full Name</label>
                                            <input type="text" value="" data-msg-required="Please enter your name." maxlength="100" class="form-control" name="name" required>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label class="required font-weight-bold text-dark">Email Address</label>
                                            <input type="email" value="" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxlength="100" class="form-control" name="email" required>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col">
                                            <label class="required font-weight-bold text-dark">Comment</label>
                                            <textarea maxlength="5000" data-msg-required="Please enter your message." rows="8" class="form-control" name="message" required></textarea>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col mb-0">
                                            <input type="submit" value="Post Comment" class="btn btn-primary btn-modern" data-loading-text="Loading...">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </article>

            </div>
        </div>
    </div>

</div>